import React, { useState, useEffect, memo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { FaArrowRight } from "react-icons/fa";
import { getSliderDataAction } from "../../../action/Admin.action";
import { imgPath } from "../../../admin/common/constant";
import mobil from "../../../assets/images/frontPageImg/backImage.png";
import captionImage from "../../../assets/images/frontPageImg/captionImage.png";

function HomeBackImgCarousel() {
  const location = useLocation();
  const [sliderData, setSliderData] = useState([]);
  const status = JSON.parse(localStorage.getItem("status"));
  const getSliderDataList = async () => {
    let resp = await getSliderDataAction();
    if (resp.code === 200) {
      setSliderData(resp.data.filter((item) => item.status === 1));
    }
  };

  useEffect(() => {
    getSliderDataList();
  }, []);

  return (
    <div className={status === 1 ? "" : "carouselTop"}>
      <Carousel controls={false} >
        {sliderData.length > 0 ? (
          sliderData.map((slide, index) => (
            <Carousel.Item key={index} interval={3000}>
              <div
                className="carousel-bg"
                style={{ backgroundImage: `url(${imgPath(slide.image)})` }}
              >
                <Container>
                  <Row className="align-items-center mt-5">
                    <Col md={6} xs={12} className="text-center">
                      <img src={captionImage} alt="Main" className="main-img" />
                    </Col>
                    <Col md={6} xs={12}>
                      <h3 className="caption-title">{slide.name}</h3>
                    </Col>
                  </Row>
                </Container>

                {slide.link && (
                  <button
                    className="know-more-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      window.open(slide.link, "_blank");
                    }}
                  >
                    Know More <FaArrowRight className="ms-2" />
                  </button>
                )}
              </div>
            </Carousel.Item>
          ))
        ) : (
          // Default Slide when no sliderData is available
          <Carousel.Item>
            <div
              className="carousel-bg"
              style={{ backgroundImage: `url(${mobil})` }}
            >
              <Container>
                <Row className="align-items-center mt-5">
                  <Col md={6} xs={12} className="text-center">
                    <img src={captionImage} alt="Main" className="main-img" />
                  </Col>
                  <Col md={6} xs={12}>
                    <h3 className="caption-title">Search, Track & Connect</h3>
                    <p className="caption-subtitle">
                      with industries best Crew, Vendors & Talent.
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>
          </Carousel.Item>
        )}
      </Carousel>
    </div>
  );
}

export default memo(HomeBackImgCarousel);
