// JobDetailsModal.js
import React from "react";
import { useState, useEffect } from "react";
import { Modal, Button, Table, Row, Col } from "react-bootstrap";
import { imgPath } from "../../../admin/common/function";
import {
  updateApplyJobsPostStatusAction,
  getPostJobsAction,
} from "../../../action/Admin.action";
import Swal from "sweetalert2";

const JobDetailsModels = ({ isOpen, toggle, jobDetails }) => {
  const [jobData, setJobData] = useState(null);

  useEffect(() => {
    // Update jobData when the job prop changes
    if (jobDetails) {
      setJobData(jobDetails);
    }
  }, [jobDetails]);

  const truncateText = (text = "", maxLength = 50) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  return (
    <Modal
      show={isOpen}
      onHide={toggle}
      size="lg"
      className="custom-modal-header-close"
      centered
    >
      <Modal.Header className="useModelHeader" closeButton>
        <Modal.Title id="profile-modal-title" className="">
          Job Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="liteModal-content">
        {jobData ? (
          <div>
            {/* <h5 className="text-white">Job Information</h5> */}
            <Row>
              <Col>
                <p className="allTextColor">
                  <strong>Job Name:</strong> {jobData.jobName || "N/A"}
                </p>
              </Col>
              <Col>
                <p className="allTextColor">
                  <strong>Company:</strong> {jobData.companyName || "N/A"}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="allTextColor">
                  <strong>Location:</strong> {jobData.location || "N/A"}
                </p>
              </Col>
              <Col>
                <p className="allTextColor">
                  <strong>Type:</strong> {jobData.jobType || "N/A"}
                </p>
              </Col>
            </Row>
            <p className="allTextColor">
              <strong>Description:</strong> {jobData.desc || "N/A"}
            </p>
            {/* <hr /> */}
            {/* <h5 className="mt-4 allTextColor">Applicants</h5> */}
            {/* {jobData.applyJobsPostData && jobData.applyJobsPostData.length > 0 ? (
                            <table className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Position</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {jobData.applyJobsPostData.map((applicant) => (
                                        <tr key={applicant._id}>
                                            <td>{applicant.userData?.businessContactName || "N/A"}</td>
                                            <td>{applicant.userData?.email || "N/A"}</td>
                                            <td>{applicant.userData?.phone || "N/A"}</td>
                                            <td>{truncateText(applicant.position || "N/A", 17)}</td>
                                            <td>{truncateText(applicant.desc || "N/A", 30)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>No applicants for this job.</p>
                        )} */}
          </div>
        ) : (
          <p>No job details available.</p>
        )}

        {/* <Button variant="secondary" onClick={toggle}>
                    Close
                </Button> */}
      </Modal.Body>
      {/* <Modal.Footer className="appledJobDetailsFooter">
                <Button variant="secondary" onClick={toggle}>
                    Close
                </Button>
            </Modal.Footer> */}
    </Modal>
  );
};

export default JobDetailsModels;
