import React, { useState, useEffect } from "react";
import { Nav, OverlayTrigger, Tooltip, Col } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import Dashboard from "../../src/assets/images/OldProImg/dashboard.png";

const SideBar = () => {
  const [name, setName] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  return (
    <div className="sidebar mt-4 pt-2 pb-4">
      <Nav>
        <div className="sidebar-sticky"></div>
        <Nav.Item className="mt-2">
          <NavLink
            to={`${process.env.PUBLIC_URL}/admin/dashboard`}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <img alt="Designation" src={Dashboard} />
            <div>Dashboard</div>
          </NavLink>
        </Nav.Item>
        <Nav.Item className="mt-2">
          <NavLink
            to={`${process.env.PUBLIC_URL}/admin/company`}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <img alt="Designation" src={Dashboard} />
            <div>Company</div>
          </NavLink>
        </Nav.Item>
        <Nav.Item className="mt-2">
          <NavLink
            to={`${process.env.PUBLIC_URL}/admin/departments`}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <img alt="Designation" src={Dashboard} />
            <div>Departments</div>
          </NavLink>
        </Nav.Item>
        <Nav.Item className="mt-2">
          <NavLink
            to={`${process.env.PUBLIC_URL}/admin/designations`}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <img alt="Designation" src={Dashboard} />
            <div>Designation</div>
          </NavLink>
        </Nav.Item>
        <Nav.Item className="mt-2">
          <NavLink
            to={`${process.env.PUBLIC_URL}/admin/role`}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <img alt="Role" src={Dashboard} />
            <div>Role</div>
          </NavLink>
        </Nav.Item>
        <Nav.Item className="mt-2">
          <NavLink
            to={`${process.env.PUBLIC_URL}/admin/languages`}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <img alt="Languages" src={Dashboard} />
            <div>Languages</div>
          </NavLink>
        </Nav.Item>
        <Nav.Item className="mt-2">
          <NavLink
            to={`${process.env.PUBLIC_URL}/admin/genres`}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <img alt="Genres" src={Dashboard} />
            <div>Genres</div>
          </NavLink>
        </Nav.Item>
        <Nav.Item className="mt-2">
          <NavLink
            to={`${process.env.PUBLIC_URL}/admin/project-types`}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <img alt="Project Type" src={Dashboard} />
            <div>Project Type</div>
          </NavLink>
        </Nav.Item>
        <Nav.Item className="mt-2">
          <NavLink
            to={`${process.env.PUBLIC_URL}/admin/location-types`}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <img alt="Location Type" src={Dashboard} />
            <div>Location Type</div>
          </NavLink>
        </Nav.Item>
        <Nav.Item className="mt-2">
          <NavLink
            to={`${process.env.PUBLIC_URL}/admin/Category`}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <img alt="Category" src={Dashboard} />
            <div> Location Category</div>
          </NavLink>
        </Nav.Item>
        <Nav.Item className="mt-2">
          <NavLink
            to={`${process.env.PUBLIC_URL}/admin/vendor-category`}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <img alt="Vendor Category" src={Dashboard} />
            <div>Vendor Category</div>
          </NavLink>
        </Nav.Item>
        <Nav.Item className="mt-2">
          <NavLink
            to={`${process.env.PUBLIC_URL}/admin/vendor-product-category`}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <img alt="Vendor Product Category" src={Dashboard} />
            <div>Vendor Product Category</div>
          </NavLink>
        </Nav.Item>

        <Nav.Item className="mt-2">
          <NavLink
            to={`${process.env.PUBLIC_URL}/admin/sliderList`}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <img alt="Vendor Product Category" src={Dashboard} />
            <div>Slider</div>
          </NavLink>
        </Nav.Item>

        <Nav.Item className="mt-2">
          <NavLink
            to={`${process.env.PUBLIC_URL}/admin/JobList`}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <img alt="Vendor Product Category" src={Dashboard} />
            <div>Job</div>
          </NavLink>
        </Nav.Item>

        <Nav.Item className="mt-2">
          <NavLink
            to={`${process.env.PUBLIC_URL}/admin/setting`}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <img alt="Vendor Product Category" src={Dashboard} />
            <div>Setting</div>
          </NavLink>
        </Nav.Item>

        <Nav.Item className="mt-2">
          <NavLink
            to={`${process.env.PUBLIC_URL}/admin/user`}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <img alt="Vendor Product Category" src={Dashboard} />
            <div>User List</div>
          </NavLink>
        </Nav.Item>
      </Nav>
    </div>
  );
};

export default SideBar;
