// import logo from "../../src/assets/images/OldProImg/logo.png"
import logo from "../../src/assets/images/OldProImg/logo1.png";
import React, { useState } from "react";
import profileImage from "../../src/assets/images/OldProImg/profile-img.jpg";
import notification from "../../src/assets/images/OldProImg/notification.jpg";
import {
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Button,
  Container,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import AlertBox from "../admin/component/AlertBox";
import { NavLink, Link } from "react-router-dom";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpens, setIsOpens] = useState(false);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const notifications = () => {
    setIsOpens(!isOpens);
  };

  const handleLogout = () => {
    // localStorage.removeItem('userType');
    // localStorage.removeItem('authorization');
    // localStorage.removeItem('loginType');
    // localStorage.removeItem('login');
    localStorage.clear();

    setTimeout(() => {
      window.location.href = "/";
    });
  };

  return (
    <header id="header" className="header fixed-top">
      <Navbar collapseOnSelect expand="lg">
        <Container fluid>
          <Navbar.Brand href="#home">
            <img src={logo} alt="" style={{ height: "70px" }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto align-items-center"></Nav>
            <Nav>
              <Dropdown className="d-inline profile-menu">
                <Dropdown.Toggle
                  variant="outline-dark"
                  className="nav-menu-btn"
                  id="dropdown-basic"
                >
                  <img src={profileImage} width="20" /> My Profile
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className="active-link border-0 mb-2"
                    size="lg"
                  >
                    <img
                      src={profileImage}
                      className="float-start"
                      width="25"
                    />
                    &ensp;SUper
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => handleLogout()}
                    className="text-center"
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <AlertBox />
    </header>
  );
}

export default Header;
