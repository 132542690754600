import React, { useState, useEffect } from 'react'
import { addContactAction } from '../../../action/Admin.action';
import { toast, ToastContainer } from "react-toastify";
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import HomeHeader from '../Header/HomeHeader';
import HeaderloginLite from '../Header/HeaderloginLite';
import HomeFooter from '../Footer/HomeFooter';
import SubFooter from '../Footer/SubFooter';
const ContactUs = () => {
    const [isDisabled, setDisabled] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        message: '',

    });
    const [formErrors, setFormErrors] = useState({});
    const status = JSON.parse(localStorage.getItem("status"));
    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();
            //setValidated(false);
            setDisabled(true);
            let formDataData = { ...formData };

            let postData = new FormData();
            for (let x in formDataData) {

                let item = formDataData[x];
                postData.append(x, item);
            }

            let resp = await addContactAction(postData);
            if (resp.code === 200) {
                toast.success('You have successfully sent message', { autoClose: 1000 });
                setFormData({
                    name: '',
                    email: '',
                    mobile: '',
                    message: '',
                });
                setValidated(false);
                setDisabled(false);
            } else {
                setDisabled(false);
                toast.error(resp.msg, { autoClose: 1000 });
            }
            return false;
        }

    };
    const handleChange = (name, event) => {
        setFormData({ ...formData, [name]: event.target.value });
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {status === 1 ? (
                <HeaderloginLite />
            ) : (
                <HomeHeader />
            )}
            <Container fluid className="p-4">
                <Container className='contactUs-section' style={{
                    marginTop: status === 1 ? "55px" : "150px",
                }}>
                    <Row className="">
                        {/* Form Section */}
                        <Col xs={12} md={6}>
                            <div
                                className="form-container contectUseForm"
                                style={{
                                    padding: "20px",
                                    borderRadius: "10px",
                                }}
                            >
                                <h3
                                    className="allTextColor"
                                    style={{
                                        paddingBottom: "20px",
                                    }}
                                >
                                    Contact Us
                                </h3>
                                <Form
                                    className="userForm"
                                    noValidate
                                    validated={validated}
                                    onSubmit={handleSubmit}
                                >
                                    <Row className="mb-3">
                                        <Col xs={12} md={6} className="mb-3">
                                            <Form.Label className="allTextColor mb-2">Your Name<span className="star"> *</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={(e) => handleChange('name', e)}
                                                placeholder="Name"
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide your name.
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col xs={12} md={6} className="mb-3">
                                            <Form.Label className="allTextColor mb-2">Email<span className="star">*</span></Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                placeholder="Email"
                                                value={formData.email}
                                                onChange={(e) => handleChange('email', e)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid email.
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs={12} md={6} className="mb-3">
                                            <Form.Label className="allTextColor mb-2">Mobile<span className="star">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="mobile"
                                                placeholder="Mobile"
                                                value={formData.mobile}
                                                onChange={(e) => handleChange('mobile', e)}
                                                required
                                                pattern="\d{10}"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid 10-digit mobile number.
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col xs={12} md={6} className="mb-3">
                                            <Form.Label className="allTextColor mb-2">Message<span className="star">*</span></Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="message"
                                                value={formData.message}
                                                onChange={(e) => handleChange('message', e)}
                                                placeholder="Enter your message"
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a message.
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                    <button
                                        className="btn btnColor"
                                        type="submit"
                                        disabled={isDisabled}
                                    >
                                        Send Message
                                    </button>
                                </Form>
                            </div>
                        </Col>

                        {/* Address Section */}
                        <Col xs={12} md={6}>
                            <div
                                className="form-container contectUsAddresCard"

                            >
                                <h3
                                    className="allTextColor"
                                    style={{
                                        paddingBottom: "20px",
                                    }}
                                >
                                    Address
                                </h3>
                                <p class="allTextColor"><i class="fas fa-building"></i> PICTURE COMPANY</p>
                                <p><a class="allTextColor"><i class="fas fa-envelope"></i>Info@pictureco.in</a></p>
                                <p><a href="https://maps.app.goo.gl/kKBJoyDY2bASgK5s5" target="_blank" class="addresscustom-link">
                                    <i class="fas fa-map-marker-alt"></i> https://maps.app.goo.gl/kKBJoyDY2bASgK5s5
                                </a></p>
                                <p class="allTextColor">  Office #1/B,
                                    A-Wing,
                                    1st floor,
                                    Vimal Apartments,<br></br>
                                    Besides Mahindra Cars Showroom,
                                    Juhu Lane,<br></br>
                                    Off SV road,
                                    Andheri (W).
                                    Mumbai - 400061</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <HomeFooter />
            <SubFooter />
            <ToastContainer />
        </>
    )
}

export default ContactUs
