import React, { useState, useEffect, useRef } from "react";
import {
  selectUserTypeRegisterAction,
  getCategoryVendorDataAction,
  getUserDetailAction,
  getFileUploderDetailAction,
  fileUploderAction,
  delelteFileAction,
} from "../../../action/Admin.action";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../slices/home";
import { Col, Row, Form, Button, Modal } from "react-bootstrap";
import {
  GetAllCountries,
  GetStatesOfCountry,
  GetCitiesOfState,
  imgPath,
} from "../../../admin/common/function";
import CheckmarkIcon from "../../../assets/images/frontPageImg/CheckmarkIcon.png";
import default_profile from "../../../assets/images/frontPageImg/default_profile.png";
import pdfIcon from "../../../assets/images/frontPageImg/pdfIcon.jpg";
import excelIcon from "../../../assets/images/frontPageImg/excelIcon.jpg";
import { FaCamera } from "react-icons/fa";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const ProfileEdit = ({ onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState("");
  const loginData = JSON.parse(localStorage.getItem("login")) || {};
  const userType = loginData.userType || null;
  const fileInputRef = useRef(null);
  const [pincodeOptions, setPincodeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [portfolioData, setPortfolioData] = useState([]);
  const [productData, setProductData] = useState([]);

  const [formData, setFormData] = useState({
    companyName: loginData.companyName || "",
    businessContactName: loginData.businessContactName || "",
    categoryId: loginData.categoryId || "",
    email: loginData.email || "",
    subCategoryId: loginData.subCategoryId || "",
    businessContactNumber: loginData.businessContactNumber || "",
    age: loginData.age || "",
    businessCountry: loginData.businessCountry || "",
    businessState: loginData.businessState || "",
    businessCity: loginData.businessCity || "",
    businessPostalCode: loginData.businessPostalCode || "",
    businessAddress: loginData.businessAddress || "",
    description: loginData.description || "",
    businessGstNumber: loginData.businessGstNumber || "",
    businessPan: loginData.businessPan || "",
    website: loginData.website || "",
    imDbLink: loginData.imDbLink || "",
    // productImg: loginData.productImg || [],
    crewResume: loginData.crewResume || "",
    // portFolio: loginData.portFolio || [],
    userProfile: loginData.userProfile || "",
    workType: loginData.workType || "",
  });
  const [image, setImage] = useState(default_profile);
  const [previewURL, setPreviewURL] = useState(null);
  const [step, setStep] = useState(1);
  const [dataList, setDataList] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isDisabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const getDataImgList = async () => {
    const resp = await getFileUploderDetailAction();
    if (resp.code === 200) {
      setPortfolioData(resp.data.portfolioData || []);
      setProductData(resp.data.productData || []);
    }
  };
  useEffect(() => {
    if (loginData.crewResume) {
      if (typeof loginData.crewResume === "string") {
        setPreviewURL(loginData.crewResume);
      } else if (loginData.crewResume instanceof File) {
        setPreviewURL(URL.createObjectURL(loginData.crewResume));
      }
    }
  }, [loginData.crewResume]);
  const fetchPincode = async (city) => {
    setLoading(true);
    setError("");
    setPincodeOptions([]);

    try {
      const response = await axios.get(
        `https://api.postalpincode.in/postoffice/${city}`
      );
      if (response.data[0].Status === "Success") {
        const pincodes = response.data[0].PostOffice.map((po) => po.Pincode);
        setPincodeOptions(pincodes);
        setFormData((prev) => ({
          ...prev,
          businessPostalCode: pincodes.includes(prev.businessPostalCode)
            ? prev.businessPostalCode
            : "",
        }));
      } else {
        setError("Pincode not found for the specified city");
        setFormData((prev) => ({ ...prev, businessPostalCode: "" }));
      }
    } catch (err) {
      setError("An error occurred while fetching the pincode");
    } finally {
      setLoading(false);
    }
  };

  const getDataList = async () => {
    let resp;
    switch (userType) {
      case 3:
        resp = await getCategoryVendorDataAction({ type: "vendor" });
        break;
      case 4:
        resp = await getCategoryVendorDataAction({ type: "crew" });
        break;
      case 5:
        resp = await getCategoryVendorDataAction({ type: "other" });
        break;
      default:
        return;
    }
    if (resp && resp.code === 200) {
      setDataList(resp.data);
    }
  };

  useEffect(() => {
    getDataList();
    getDataImgList();
  }, [userType]);

  useEffect(() => {
    if (formData.categoryId && dataList.length > 0) {
      const selectedCategory = dataList.find(
        (item) => item._id === formData.categoryId
      );

      if (selectedCategory) {
        setSubCategories(selectedCategory.subCategory || []);
        const isSubCategoryValid = selectedCategory.subCategory.some(
          (sub) => sub._id === formData.subCategoryId
        );
        if (!isSubCategoryValid) {
          setFormData((prev) => ({ ...prev, subCategoryId: "" }));
        }
      } else {
        setSubCategories([]);
        setFormData((prev) => ({ ...prev, subCategoryId: "" }));
      }
    }
  }, [dataList, formData.categoryId]);
  useEffect(() => {
    if (loginData?.userProfile) {
      setImage(imgPath(loginData.userProfile));
    } else {
      setImage(default_profile);
    }
  }, [loginData?.userProfile]);

  const validateStep = () => {
    const phoneNumberPattern = /^(?:\+91)?[0-9]{10}$/;
    switch (step) {
      case 1:
        return (
          formData.businessContactName &&
          phoneNumberPattern.test(formData.businessContactNumber) &&
          (userType !== 3 || userType !== 4 || formData.categoryId) &&
          (userType !== 5 || formData.workType) &&
          formData.email &&
          // formData.subCategoryId &&
          formData.businessCountry &&
          formData.businessState &&
          formData.businessCity &&
          formData.businessPostalCode &&
          (userType !== 3 || formData.companyName)
        );
      case 2:
        return formData.businessAddress;
      default:
        return false;
    }
  };

  const handleNextStep = (event) => {
    event.preventDefault();
    if (validateStep()) {
      if (step === 1) {
        setStep(2);
      }
    } else {
      setValidated(true);
    }
  };

  const handlePreviousStep = (event) => {
    event.preventDefault();
    if (step === 2) {
      setStep(1);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    // Validate the form
    if (form.checkValidity() === false || !validateStep()) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    let formDataData = { ...formData, userType };

    const postData = new FormData();

    if (formDataData.crewResume) {
      postData.append("crewResume", formDataData.crewResume);
    }

    for (let key in formDataData) {
      if (formDataData.hasOwnProperty(key)) {
        if (key !== "crewResume") {
          postData.append(key, formDataData[key]);
        }
      }
    }
    try {
      const resp = await selectUserTypeRegisterAction(postData);

      if (resp.code === 200) {
        // Handle success
        localStorage.setItem("login", JSON.stringify(resp.data));
        setSuccessMessage("Profile updated successfully.");
        dispatch(
          setAlert({
            open: true,
            severity: "success",
            msg: "Profile updated successfully.",
            type: "",
          })
        );
        setTimeout(() => {
          onClose();
        }, 3000);
      } else {
        toast.error(resp.msg);
        dispatch(
          setAlert({ open: true, severity: "error", msg: resp.msg, type: "" })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          severity: "error",
          msg: "An error occurred. Please try again.",
          type: "",
        })
      );
    }
  };

  const handleChange = (name, event) => {
    let value = event.target.value;
    console.log("value", value);
    if (name === "crewResume") {
      let file = event.target.files[0];

      // Check if the file is a PDF
      if (file && file.type === "application/pdf") {
        setFormData({ ...formData, [name]: file });
        setPreviewURL(URL.createObjectURL(file));
      } else {
        toast.error("Please upload a valid PDF file.");
        setFormData({ ...formData, [name]: null });
        setPreviewURL(null);
      }
    } else {
      let updatedFormData = { ...formData, [name]: value };
      if (name === "categoryId") {
        const selectedCategory = dataList.find((item) => item._id === value);
        setSubCategories(selectedCategory ? selectedCategory.subCategory : []);
        updatedFormData.subCategoryId = "";
      }
      if (name === "businessCity") {
        setFormData({ ...formData, [name]: value });
        fetchPincode(value);
      } else {
        setFormData(updatedFormData);
      }
    }
  };
  const handleFileUpload = async (file, type) => {
    const formData = new FormData();
    formData.append("fileType", type);
    formData.append("file", file);

    try {
      const resp = await fileUploderAction(formData);
      if (resp.data) {
        getDataImgList();
      }
    } catch (error) {
      console.error("File upload failed:", error);
    }
  };
  const removeFile = async (fileId, type) => {
    const targetState = type === "2" ? productData : portfolioData;
    const fileToDelete = targetState.find((file) => file._id === fileId);

    if (!fileToDelete) {
      console.error("File not found in state");
      return;
    }

    try {
      const resp = await delelteFileAction({
        fileType: fileToDelete.fileType,
        _id: fileToDelete._id,
      });

      if (resp.code === 200) {
        getDataImgList();
      } else {
        console.error("Error from API:", resp.msg || "Unknown error");
      }
    } catch (error) {
      console.error("File deletion failed:", error);
    }
  };

  const handleContinue = () => {
    setShowSuccessModal(false);
    navigate(`${process.env.PUBLIC_URL}/user/dashboard`);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);

      setFormData((prev) => ({ ...prev, userProfile: file }));
    }
  };
  const isArtistCategory = () => {
    const selectedCategory = dataList.find(
      (item) => item._id === formData.categoryId
    );
    return selectedCategory?.name === "Artist";
  };
  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveImage = () => {
    setImage(default_profile);
    setFormData((prev) => ({ ...prev, userProfile: null }));

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  useEffect(() => {
    if (formData.businessCity) {
      fetchPincode(formData.businessCity);
    }
  }, [formData.businessCity]);

  return (
    <>
      <Form
        className="userForm"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        {successMessage && (
          <div className="alert alert-success">{successMessage}</div>
        )}
        {step === 1 ? (
          <>
            <div className="row d-flex justify-content-end">
              <div className="col-md-7">
                <div className="pb-4">
                  <div className="image-upload-container text-center">
                    <img
                      // src={image}
                      src={
                        // image ||
                        // imgPath(loginData?.companyData?.businessLogo) ||
                        image || imgPath(loginData.userProfile)
                      }
                      width="100"
                      className="rounded-circle"
                      alt="Profile"
                      style={{ cursor: "pointer" }}
                      onClick={triggerFileInput}
                    />
                    <input
                      type="file"
                      id="imageUpload"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageUpload}
                      ref={fileInputRef}
                    />

                    <div
                      className="camera-icon-overlay"
                      onClick={triggerFileInput}
                    >
                      <FaCamera size={20} color="white" />
                    </div>
                  </div>
                  {formData.userProfile && formData.userProfile !== "" && (
                    <Button
                      size="sm"
                      className="mt-2 btnColor"
                      onClick={handleRemoveImage}
                    >
                      Remove
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <Row>
              {userType === 3 && (
                <Col md="6">
                  <div className="mb-2">
                    <Form.Group>
                      <Form.Label className="allTextColor mb-2">
                        Company Name<span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="companyName"
                        className=""
                        value={formData.companyName}
                        onChange={(e) => handleChange("companyName", e)}
                        autocomplete="off"
                        required
                        readOnly
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Company Name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </Col>
              )}
              <Col md="6">
                <div className="mb-2">
                  <Form.Group>
                    <Form.Label className="allTextColor mb-2">
                      Contact Name<span className="star">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="businessContactName"
                      value={formData.businessContactName}
                      onChange={(e) => handleChange("businessContactName", e)}
                      required
                      readOnly
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid Contact Name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Col>
              {userType === 5 && (
                <Col md="6">
                  <div className="mb-2">
                    <Form.Group>
                      <Form.Label className="allTextColor mb-2">
                        Email ID<span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={(e) => handleChange("email", e)}
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        required
                        readOnly
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Email ID.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </Col>
              )}
            </Row>

            <Row>
              {(userType === 3 || userType === 4) && (
                <Col md="6">
                  <div className="mb-2">
                    <Form.Group controlId="categoryId">
                      <Form.Label className="allTextColor mb-2">
                        Category
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="categoryId"
                        className="customC-select"
                        value={formData.categoryId}
                        onChange={(e) => handleChange("categoryId", e)}
                      >
                        <option value="">Select Category</option>
                        {dataList.map((category) => (
                          <option key={category._id} value={category._id}>
                            {category.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </div>
                </Col>
              )}
              {(userType === 3 || userType === 4) && (
                <Col md="6">
                  <div className="mb-2">
                    <Form.Group>
                      <Form.Label className="allTextColor mb-2">
                        Email ID<span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={(e) => handleChange("email", e)}
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        required
                        readOnly
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Email ID.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </Col>
              )}
            </Row>

            <Row>
              {(userType === 3 || userType === 4) && (
                <Col md="6">
                  <div className="mb-2">
                    <Form.Group controlId="subCategoryId">
                      <Form.Label className=" allTextColor mb-2">
                        Sub Category
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="subCategoryId"
                        value={formData.subCategoryId}
                        onChange={(e) => handleChange("subCategoryId", e)}
                        disabled={subCategories.length === 0}
                        className="customC-select"
                      >
                        <option value="">Select Sub Category</option>
                        {subCategories.map((subCategory) => (
                          <option key={subCategory._id} value={subCategory._id}>
                            {subCategory.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </div>
                </Col>
              )}
              <Col md="6">
                <div className="mb-2">
                  <Form.Group>
                    <Form.Label className="allTextColor mb-2">
                      Contact No<span className="star">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="businessContactNumber"
                      value={formData.businessContactNumber}
                      onChange={(e) => handleChange("businessContactNumber", e)}
                      required
                      pattern="(\+91)?\d{10}"
                      minLength="10"
                      maxLength="13"
                      readOnly
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid Contact No.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Col>
              {userType === 5 && (
                <Col md="6">
                  <div className="mb-2">
                    <Form.Group>
                      <Form.Label className="allTextColor mb-2">
                        Work Type<span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="workType"
                        value={formData.workType || ""}
                        onChange={(e) => handleChange("workType", e)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Work Type
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </Col>
              )}
            </Row>
            {userType === 4 && isArtistCategory() && (
              <Row>
                <Col md={6}>
                  <div className="mb-2">
                    <Form.Label className="allTextColor mb-2">
                      Age<span className="star"> *</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="filedbg cmnsz customC-select"
                      value={formData.age ? formData.age : ""}
                      onChange={(e) => handleChange("age", e)}
                      required
                    >
                      <option value="">Select Age</option>
                      <option value="1-10">1-10</option>
                      <option value="11-18">11-18</option>
                      <option value="19-25">19-25</option>
                      <option value="26-35">26-35</option>
                      <option value="36-50">36-50</option>
                      <option value="50 & above">50 & above</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid Age.
                    </Form.Control.Feedback>
                  </div>
                </Col>
              </Row>
            )}

            <Row>
              <Col md="6">
                <div className="mb-2">
                  <Form.Group>
                    <Form.Label className="allTextColor mb-2">
                      Country<span className="star">*</span>
                    </Form.Label>
                    <Form.Select
                      name="businessCountry"
                      className="customC-select"
                      value={formData.businessCountry}
                      onChange={(e) => handleChange("businessCountry", e)}
                      required
                    >
                      <GetAllCountries />
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid Country.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-2">
                  <Form.Group>
                    <Form.Label className="allTextColor mb-2">
                      State<span className="star">*</span>
                    </Form.Label>
                    <Form.Select
                      name="businessState"
                      value={formData.businessState}
                      className="customC-select"
                      onChange={(e) => handleChange("businessState", e)}
                      required
                    >
                      <GetStatesOfCountry
                        countryCode={formData.businessCountry}
                      />
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid State.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-2">
                  <Form.Group>
                    <Form.Label className="allTextColor mb-2">
                      City<span className="star">*</span>
                    </Form.Label>
                    <Form.Select
                      name="businessCity"
                      value={formData.businessCity}
                      className="customC-select"
                      onChange={(e) => handleChange("businessCity", e)}
                      required
                    >
                      <GetCitiesOfState
                        countryCode={formData.businessCountry}
                        stateCode={formData.businessState}
                      />
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid City.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-2">
                  <Form.Group>
                    <Form.Label className="allTextColor mb-2">
                      Pin Code<span className="star">*</span>
                    </Form.Label>
                    <Form.Select
                      className="customC-select"
                      name="businessPostalCode"
                      value={formData.businessPostalCode || ""}
                      onChange={(e) => handleChange("businessPostalCode", e)}
                      required
                    >
                      <option value="" disabled>
                        Select Pin Code
                      </option>
                      {pincodeOptions.map((pin, index) => (
                        <option key={index} value={pin}>
                          {pin}
                        </option>
                      ))}
                    </Form.Select>
                    {error && <div className="text-danger">{error}</div>}
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Button
                  className="mt-3 btnColor"
                  onClick={handleNextStep}
                  disabled={isDisabled}
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <>
            {/* Step 2 Form Fields */}
            <Row>
              <Col md="6">
                <div className="mb-2">
                  <Form.Group>
                    <Form.Label className="allTextColor mb-2">
                      Address<span className="star">*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="businessAddress"
                      rows="2"
                      value={formData.businessAddress}
                      onChange={(e) => handleChange("businessAddress", e)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid Address.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-2">
                  <Form.Group>
                    <Form.Label className="allTextColor mb-2">
                      Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      rows="2"
                      value={formData.description}
                      onChange={(e) => handleChange("description", e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid Description.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Col>
            </Row>
            {(userType === 3 || userType === 4) && (
              <Row>
                <Col md="6">
                  <div className="mb-2">
                    <Form.Group>
                      <Form.Label className="allTextColor mb-2">
                        GST Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="businessGstNumber"
                        value={formData.businessGstNumber}
                        onChange={(e) => handleChange("businessGstNumber", e)}
                        pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                        minLength={15}
                        maxLength={15}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid GST Number.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-2">
                    <Form.Group>
                      <Form.Label className="allTextColor mb-2">
                        Pan No.
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="businessPan"
                        value={formData.businessPan}
                        onChange={(e) => handleChange("businessPan", e)}
                        pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                        minLength={10}
                        maxLength={10}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Pan No.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            )}
            {userType === 4 && (
              <Row>
                <Col md="6">
                  <div className="mb-2">
                    <Form.Group>
                      <Form.Label className="allTextColor mb-2">
                        Website
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="website"
                        value={formData.website}
                        onChange={(e) => handleChange("website", e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Website.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-2">
                    <Form.Group>
                      <Form.Label className="allTextColor mb-2">
                        IMDb Link
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="imDbLink"
                        value={formData.imDbLink}
                        onChange={(e) => handleChange("imDbLink", e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid IMDb Link.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              {userType === 3 && (
                // <Col md="6">
                //     <div className="mb-2">
                //         <Form.Group>
                //             <Form.Label className="allTextColor mb-2">Add Product Image</Form.Label>
                //             <Form.Control
                //                 type="file"
                //                 name="productImg"
                //                 onChange={e => handleChange('productImg', e)}
                //             />
                //         </Form.Group>
                //     </div>
                // </Col>

                <Col md="6">
                  <div className="mb-2">
                    <Form.Group>
                      <Form.Label className="allTextColor mb-2">
                        Add Product Image
                      </Form.Label>
                      <div
                        className="file-upload-container"
                        onClick={() =>
                          document.getElementById("productImageInput").click()
                        }
                      >
                        <Form.Control
                          id="productImageInput"
                          type="file"
                          name="productImg"
                          accept="image/png, image/jpeg"
                          onChange={(e) =>
                            handleFileUpload(e.target.files[0], "2")
                          }
                          className="file-input"
                        />
                        <div className="plus-icon">+</div>
                      </div>
                      {formErrors.productImage_error && (
                        <div className="error-message">
                          {formErrors.productImage_error}
                        </div>
                      )}
                    </Form.Group>
                    <div className="image-previews">
                      {productData.map((file, index) => (
                        <div key={file._id} className="preview-item">
                          <img
                            src={imgPath(file.filePath)}
                            alt={`Product Image ${index + 1}`}
                            className="preview-image"
                          />
                          <Button
                            className="remove-icon"
                            onClick={() => removeFile(file._id, "2")}
                          >
                            &times;
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
              )}
              {userType === 4 && (
                <Col md="6">
                  <div className="mb-2">
                    <Form.Group>
                      <Form.Label className="allTextColor mb-2">
                        Resume
                      </Form.Label>
                      <div
                        className="file-upload-container"
                        onClick={() =>
                          document.getElementById("ResumeInput").click()
                        }
                      >
                        <Form.Control
                          id="ResumeInput"
                          type="file"
                          name="crewResume"
                          accept="application/pdf"
                          onChange={(e) => handleChange("crewResume", e)}
                          className="file-input"
                        />
                        <div className="plus-icon">+</div>
                      </div>
                      {previewURL && (
                        <img
                          src={pdfIcon}
                          alt="PDF Icon"
                          style={{
                            width: "60px",
                            height: "60px",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            marginTop: "10px",
                          }}
                        />
                      )}
                      {formErrors.portfolio_error && (
                        <div className="error-message">
                          {formErrors.portfolio_error}
                        </div>
                      )}
                    </Form.Group>
                  </div>
                </Col>
              )}
              {(userType === 3 || userType === 4) && (
                <Col md="6">
                  <div className="mb-2">
                    <Form.Group>
                      <Form.Label className="allTextColor mb-2">
                        Add Portfolio
                      </Form.Label>
                      <div
                        className="file-upload-container"
                        onClick={() =>
                          document.getElementById("portfolioInput").click()
                        }
                      >
                        <Form.Control
                          id="portfolioInput"
                          type="file"
                          name="portFolio"
                          onChange={(e) =>
                            handleFileUpload(e.target.files[0], "1")
                          }
                          className="file-input"
                        />
                        <div className="plus-icon">+</div>
                      </div>
                      {formErrors.portfolio_error && (
                        <div className="error-message">
                          {formErrors.portfolio_error}
                        </div>
                      )}
                    </Form.Group>
                    <div
                      className="file-previews"
                      style={{
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                        marginTop: "6px",
                      }}
                    >
                      {portfolioData?.map((file, index) => (
                        <div
                          key={file._id}
                          className="file-preview-item"
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          {/* Check if the file is a PDF */}
                          {file.filePath.endsWith(".pdf") ? (
                            // <iframe
                            //     src={imgPath(file.filePath)}
                            //     style={{ width: '60px', height: '60px', border: '1px solid #ddd', borderRadius: '5px' }}
                            //     title={`PDF Preview ${index}`}
                            // />
                            <img
                              src={pdfIcon}
                              alt="PDF Icon"
                              style={{
                                width: "60px",
                                height: "60px",
                                border: "1px solid #ddd",
                                borderRadius: "5px",
                              }}
                            />
                          ) : (
                            // Otherwise, treat the file as an image
                            <img
                              src={imgPath(file.filePath)}
                              alt={`Preview ${index}`}
                              style={{
                                width: "60px",
                                height: "60px",
                                borderRadius: "5px",
                                objectFit: "cover",
                              }}
                            />
                          )}
                          <Button
                            className="remove-icon"
                            style={{
                              position: "absolute",
                              top: "-5px",
                              right: "-5px",
                              backgroundColor: "#6c63ff",
                              borderRadius: "50%",
                              padding: "5px",
                              fontSize: "12px",
                            }}
                            onClick={() => removeFile(file._id, "1")}
                          >
                            &times;
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>

                // </div>
                // </Col>
              )}
            </Row>
            <div>
              <Button
                className="mt-3 createbtnColor"
                type="submit"
                disabled={isDisabled}
                style={{
                  fontSize: "1rem",
                }}
              >
                Profile Update
              </Button>{" "}
              <Button
                className="mt-3 btnColor"
                onClick={handlePreviousStep}
                style={{
                  fontSize: "1rem",
                  marginLeft: "10px",
                }}
              >
                Back
              </Button>
            </div>
          </>
        )}
      </Form>
      <ToastContainer position="top-right" autoClose={3000} />
      {/* Success Modal */}
      <Modal
        Modal
        show={showSuccessModal}
        onHide={() => {}}
        centered
        backdrop="static" // Prevent closing the modal by clicking outside
      >
        <Modal.Body className="text-center">
          {/* Blue checkmark icon */}
          <div className="my-4">
            <img
              src={CheckmarkIcon}
              width={50}
              height={50}
              alt="Success Checkmark"
            />
          </div>
          <h4>Registration Successful!</h4>
          <p>You have successfully registered!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleContinue}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProfileEdit;
