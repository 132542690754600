import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import HeaderloginLite from "../Header/HeaderloginLite";
import HomeHeader from "../Header/HomeHeader";
import HomeFooter from "../Footer/HomeFooter";
import SubFooter from "../Footer/SubFooter";
import { useNavigate } from "react-router";
import { getViewNotificationListDataAction } from "../../../action/Admin.action";
import NotificationList from "../NotificationList";
import Pagination from "../../../admin/common/Pagination";

const AllNotification = () => {
  const navigate = useNavigate();
  const status = JSON.parse(localStorage.getItem("status"));

  const [dataList, setDataList] = useState([]);
  const [formData, setFormData] = useState({
    page: 0,
    limit: 5,
    sortName: "createdAt",
    sortBy: "DESC",
    totalItems: 0,
  });

  const getDataList = async () => {
    try {
      const payload = {
        page: formData.page,
        limit: formData.limit,
        sortName: formData.sortName,
        sortBy: formData.sortBy,
      };

      const resp = await getViewNotificationListDataAction(payload);

      if (resp.code === 200) {
        setDataList(resp.data || []);

        setFormData((prev) => ({
          ...prev,
          totalItems: resp.count || 0,
        }));
      } else {
        setDataList([]);
        setFormData((prev) => ({
          ...prev,
          totalItems: 0,
        }));
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    getDataList();
  }, [formData.page, formData.limit]);

  const getTimeDifference = (createdAt) => {
    const createdTime = new Date(createdAt);
    const currentTime = new Date();
    const diffInSeconds = Math.floor((currentTime - createdTime) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} sec ago`;
    } else if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)} min ago`;
    } else if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)} hr ago`;
    } else {
      return `${Math.floor(diffInSeconds / 86400)} day ago`;
    }
  };

  return (
    <>
      {status === 1 ? <HeaderloginLite /> : <HomeHeader />}

      <Container fluid className="p-4" style={{ minHeight: "500px" }}>
        <Row className="align-items-center mb-3 px-2">
          <Col xs={6}>
            <h5 className="m-0">All Notifications</h5>
          </Col>

          <Col xs={6} className="text-end">
            <button className="btn btnColor" onClick={() => navigate(-1)}>
              Back
            </button>
          </Col>
        </Row>

        {/* Notification List */}
        <NotificationList
          dataList={dataList}
          getTimeDifference={getTimeDifference}
        />

        {/* Pagination */}
        {formData.totalItems > formData.limit && (
          <Pagination
            currentPage={formData.page + 1}
            itemsPerPage={formData.limit}
            totalItems={formData.totalItems}
            setPage={(e) => setFormData({ ...formData, ...e })}
          />
        )}
      </Container>
      <HomeFooter />
      <SubFooter />
    </>
  );
};

export default AllNotification;
