import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SenderEnquiryDetailModel from "./Pages/SenderEnquiryDetailModel";
import { getEnquiryAction } from "../../action/Admin.action";

const NotificationList = ({ dataList, getTimeDifference }) => {
  const location = useLocation();
  const [enquiryModalOpen, setEnquiryModalOpen] = useState(false);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);

  const openEnquiryModal = async (id) => {
    try {
      const response = await getEnquiryAction({ _id: id });
      setSelectedEnquiry(response?.data);
      setEnquiryModalOpen(true);
    } catch (error) {
      console.error("Error fetching enquiry data:", error);
    }
  };

  const closeEnquiryModal = () => {
    setEnquiryModalOpen(false);
    setSelectedEnquiry(null);
  };

  const truncateMessage = (message, charLimit = 40) => {
    if (!message) return "";

    if (message.length > charLimit) {
      return message.slice(0, charLimit) + " ...";
    }

    return message;
  };

  const containerClass =
    location.pathname === "/allNotifications"
      ? "notification_section"
      : "notification-items";
  return (
    <>
      {/* Notification List */}
      <div className={containerClass}>
        {dataList.map((notif) => (
          <div key={notif.id} className="notification-item">
            <div className="d-flex justify-content-between m-0">
              <p className="m-0 notify_name">
                {notif.type === "enquiry" ? (
                  <Link
                    className="enq_title"
                    onClick={() => openEnquiryModal(notif.referenceId)}
                  >
                    {notif.title}
                  </Link>
                ) : (
                  <strong>{notif.title}</strong>
                )}
              </p>
              <p className="notify_msg">{getTimeDifference(notif.createdAt)}</p>
            </div>

            <p className="m-0 notify_msg">
              {location.pathname === "/allNotifications"
                ? notif.message
                : truncateMessage(notif.message)}
            </p>
          </div>
        ))}
      </div>
      {/* Enquiry view modal */}
      <SenderEnquiryDetailModel
        isOpen={enquiryModalOpen}
        toggle={closeEnquiryModal}
        fetchEnquiry={selectedEnquiry}
      />
    </>
  );
};

export default NotificationList;
