import React, { Fragment, useEffect } from "react";
import HomeHeader from "../Header/HomeHeader";
import HomeFooter from "../Footer/HomeFooter";
import SubFooter from "../Footer/SubFooter";
import HeaderloginLite from "../Header/HeaderloginLite";
import { Card, Accordion } from "react-bootstrap";

const faqData = [
  {
    question: "What is FilmCrew, and what services do you provide?",
    answer:
      " FilmCrew is a production management platform that streamlines workflows for film and OTT projects. We provide tools for scheduling, budgeting, location scouting, casting, and post-production coordination, helping production houses.",
  },
  {
    question: "How can I register as a filmmaker or crew member?",
    answer:
      " You can sign up on our website by providing your details, portfolio, and relevant experience. Once registered, you can apply for projects, connect with industry professionals, and access tools.",
  },
  {
    question: "How does the platform help production houses?",
    answer:
      " FilmCrew simplifies project management by offering:\n• Automated scheduling and budgeting tools\n• Secure collaboration between departments\n• Real-time updates on production status\n• Talent and crew database for easy hiring\n• Integration with post-production workflows",
  },
  {
    question: " Is FilmCrew available internationally?",
    answer:
      " Yes, we are open to international markets. If any international crew, vendor, or production company is interested in collaborating with the Indian media and entertainment industry, we welcome the opportunity.",
  },
  {
    question: "How do I apply for jobs through FilmCrew?",
    answer:
      " Once registered, you can browse available projects, submit applications, and track your job status. Production houses can also directly reach out to you based on your profile.",
  },
  {
    question: "What are the subscription plans or pricing models?",
    answer:
      " We offer :  • Basic (Free): Limited access to job listings and networking\n• Pro (Paid): Full access to project management tools, premium job postings, and advanced analytics",
  },
  {
    question: "How to delete or deactivate my profile / account?",
    answer:
      " Go to Edit Profile and scroll down to find the option to delete or deactivate your profile.",
  },
];

const Faq = () => {
  const status = JSON.parse(localStorage.getItem("status"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      {status === 1 ? <HeaderloginLite /> : <HomeHeader />}

      <section
        className="container mb-4"
        style={{ marginTop: status === 1 ? "25px" : "125px" }}
      >
        <div className="text-center mb-4">
          <h2 className="allTextColor">FAQ</h2>
        </div>

        <Accordion defaultActiveKey="0">
          {faqData.map((faq, index) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Accordion.Header>
                Q.{index + 1}. {faq.question}
              </Accordion.Header>
              {/* <Accordion.Body>Ans. {faq.answer}</Accordion.Body> */}
              <Accordion.Body>
                Ans.
                <span style={{ whiteSpace: "pre-line" }}>{faq.answer}</span>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </section>
      <HomeFooter />
      <SubFooter />
    </Fragment>
  );
};

export default Faq;
