import { Route, Routes, useNavigate } from "react-router-dom";
import Header from "../SuperAdmin/Header";
//import Dashboard from './pages/Dashboard';
import AdminDashboard from "./Dashboard";
//import NotFound from '../../admin/pages/NotFound';
import {
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Button,
  Container,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import SideBar from "./SideBar";
import CompanyListing from "./company/CompanyListing";
import CompanyAddEdit from "./company/Company";
import CompanyEdit from "./company/EditCompany";
import Department from "./departments/Department";

import DesignationAddEdit from "./designation/AddEdit";
import DesignationList from "./designation/List";

import RoleAddEdit from "./role/AddEdit";
import RoleList from "./role/List";

import LanguageAddEdit from "./language/AddEdit";
import LanguageList from "./language/List";

import GenreAddEdit from "./genre/AddEdit";
import GenreList from "./genre/List";

import ProjectTypeAddEdit from "./projectType/AddEdit";
import ProjectTypeList from "./projectType/List";

import DepartmentListing from "./departments/DepartmentListing";
import DepartmentAddEdit from "./departments/Department";

import LocationTypeEdit from "./location/LocationTypeEdit";
import LocationTypeList from "./location/List";

import CategoryEdit from "./category/CategoryEdit";
import CategoryList from "./category/List";

import VendorCategoryEdit from "./vendorCategory/CategoryEdit";
import VendorCategoryList from "./vendorCategory/List";

import VendorProductCategoryEdit from "./vendoreProductcategory/CategoryEdit";
import VendorProductCategoryList from "./vendoreProductcategory/List";

import SliderAdd from "./slider/List";
import SliderList from "./slider/SliderList";
import SettingEdit from "./setting/AddEditSetting";
import SettingList from "./setting/List";

import UserList from "./user/List";
function SuperAdminLayout() {
  return (
    <>
      <div>
        <div className="mt-5 pt-5">
          <Header />
        </div>
        <main id="main" className="main">
          <Row className="mx-0">
            {/* <Col md={1} className='mt-3'>
                <SideBar />
            </Col> */}
            <Col md={12} className="mt-5">
              <Routes>
                <Route exact path="/" element={<AdminDashboard />} />
                {/* {`${process.env.PUBLIC_URL}/admin/dashboard`} */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/admin/dashboard`}
                  element={<AdminDashboard />}
                />
                <Route
                  exact
                  path="/admin/company"
                  element={<CompanyListing />}
                />
                <Route
                  exact
                  path="/admin/company/add"
                  element={<CompanyAddEdit />}
                />
                <Route
                  exact
                  path="/admin/company/edit"
                  element={<CompanyEdit />}
                />

                <Route
                  exact
                  path="/admin/departments"
                  element={<DepartmentListing />}
                />
                <Route
                  exact
                  path="/admin/departments/add"
                  element={<DepartmentAddEdit />}
                />
                <Route
                  exact
                  path="/admin/departments/edit"
                  element={<DepartmentAddEdit />}
                />

                <Route
                  exact
                  path="/admin/languages"
                  element={<LanguageList />}
                />
                <Route
                  exact
                  path="/admin/language/add"
                  element={<LanguageAddEdit />}
                />
                <Route
                  exact
                  path="/admin/language/edit"
                  element={<LanguageAddEdit />}
                />

                <Route exact path="/admin/genres" element={<GenreList />} />
                <Route
                  exact
                  path="/admin/genre/add"
                  element={<GenreAddEdit />}
                />
                <Route
                  exact
                  path="/admin/genre/edit"
                  element={<GenreAddEdit />}
                />

                <Route
                  exact
                  path="/admin/project-types"
                  element={<ProjectTypeList />}
                />
                <Route
                  exact
                  path="/admin/project-type/add"
                  element={<ProjectTypeAddEdit />}
                />
                <Route
                  exact
                  path="/admin/project-type/edit"
                  element={<ProjectTypeAddEdit />}
                />

                <Route
                  exact
                  path="/admin/designations"
                  element={<DesignationList />}
                />
                <Route
                  exact
                  path="/admin/designation/add"
                  element={<DesignationAddEdit />}
                />
                <Route
                  exact
                  path="/admin/designation/edit"
                  element={<DesignationAddEdit />}
                />

                <Route exact path="/admin/role" element={<RoleList />} />
                <Route exact path="/admin/role/add" element={<RoleAddEdit />} />
                <Route
                  exact
                  path="/admin/role/edit"
                  element={<RoleAddEdit />}
                />

                <Route
                  exact
                  path="/admin/location-types"
                  element={<LocationTypeList />}
                />
                <Route
                  exact
                  path="/admin/location-types/add"
                  element={<LocationTypeEdit />}
                />
                <Route
                  exact
                  path="/admin/location-types/edit"
                  element={<LocationTypeEdit />}
                />

                <Route
                  exact
                  path="/admin/category"
                  element={<CategoryList />}
                />
                <Route
                  exact
                  path="/admin/category/add"
                  element={<CategoryEdit />}
                />
                <Route
                  exact
                  path="/admin/category/edit"
                  element={<CategoryEdit />}
                />

                <Route
                  exact
                  path="/admin/vendor-category"
                  element={<VendorCategoryList />}
                />
                <Route
                  exact
                  path="/admin/vendor-category/add"
                  element={<VendorCategoryEdit />}
                />
                <Route
                  exact
                  path="/admin/vendor-category/edit"
                  element={<VendorCategoryEdit />}
                />

                <Route
                  exact
                  path="/admin/vendor-product-category"
                  element={<VendorProductCategoryList />}
                />
                <Route
                  exact
                  path="/admin/vendor-product-category/add"
                  element={<VendorProductCategoryEdit />}
                />
                <Route
                  exact
                  path="/admin/vendor-product-category/edit"
                  element={<VendorProductCategoryEdit />}
                />

                {/* Slider */}
                <Route exact path="/admin/slider" element={<SliderAdd />} />
                <Route
                  exact
                  path="/admin/sliderList"
                  element={<SliderList />}
                />
                <Route
                  exact
                  path="/admin/slider/edit"
                  element={<SliderAdd />}
                />

                <Route exact path="/admin/setting" element={<SettingList />} />
                <Route
                  exact
                  path="/admin/setting/add"
                  element={<SettingEdit />}
                />
                <Route
                  exact
                  path="/admin/setting/edit"
                  element={<SettingEdit />}
                />

                <Route exact path="/admin/user" element={<UserList />} />

                <Route exact path="*" element={<AdminDashboard />} />
              </Routes>
            </Col>
          </Row>
        </main>
      </div>
    </>
  );
}

export default SuperAdminLayout;
