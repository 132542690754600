import React, { Fragment, useEffect, useState } from "react";
import { useLocation, Link } from 'react-router-dom';
import { Container, Card, Button, Row, Col, Form, Modal, Tab, Nav, Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router";
import HomeHeader from "../Header/HomeHeader";
import Headerlogin from "../Header/HeaderloginLite";
import searchIcon from "../../../assets/images/frontPageImg/interface.png";
import HomeFooter from "../Footer/HomeFooter";
import { getPostJobsAction, applyJobsPostAction, getPostJobsListOtherUsersAction, getMyAppliedJobsAction } from "../../../action/Admin.action";
import { useDispatch } from 'react-redux';
import SubFooter from "../Footer/SubFooter";
import LoginWithOtp from "./LoginWithOtp";
import PostApplyDetailsModel from "./PostApplyDetailsModel";
import { checkFile } from "../../../admin/common/function";
import JobDetailsModel from "./JobDetailsModels";

const Job = ({ selected }) => {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isDisabled, setDisabled] = useState(false);
    const [validated, setValidated] = useState(false);
    const status = JSON.parse(localStorage.getItem("status"));
    const [showApplyModal, setShowApplyModal] = useState(false);
    const handleOpenApplyModal = () => setShowApplyModal(true);
    const handleCloseApplyModal = () => setShowApplyModal(false);
    const [allJobsData, setAllJobsData] = useState([]);
    const [myPostingData, setMyPostingData] = useState([]);
    const [appliedJobsData, setAppliedJobsData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        desc: '',
        postJobsId: ''
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [selectedJobDetails, setSelectedJobDetails] = useState(null);
    const [isJobDetailsModalOpen, setIsJobDetailsModalOpen] = useState(false);

    const openJobDetailsModal = (jobDetail) => {
        setSelectedJobDetails(jobDetail);
        setIsJobDetailsModalOpen(true);
    };

    const closeJobDetailsModal = () => {
        setSelectedJobDetails(null);
        setIsJobDetailsModalOpen(false);
    };
    const toggleModal = () => setModalOpen(!modalOpen);

    const openModalWithJobData = (job) => {
        setSelectedJob(job);
        setModalOpen(true);
    };
    const [activeTab, setActiveTab] = useState("allJobs"); 
   
    const handleApplyNow = (jobId) => {
        setFormData({ ...formData, postJobsId: jobId });
        setShowModal(true);
    };

    const fetchData = async () => {
        setLoading(true); 
        let resp;

        if (activeTab === "allJobs") {
            resp = await getPostJobsListOtherUsersAction();
            if (resp && resp.code === 200) {
                setAllJobsData(resp.data);
            }
        } else if (activeTab === "myPosting") {
            resp = await getPostJobsAction({ status: 1 });
            if (resp && resp.code === 200) {
                setMyPostingData(resp.data);
            }
        } else if (activeTab === "appliedJobs") {
            resp = await getMyAppliedJobsAction();
            if (resp && resp.code === 200) {
                const jobsPostDataArray = resp.data.map(item => item.jobsPostData);
                setAppliedJobsData(jobsPostDataArray);
            }
        }

        setLoading(false); 
    };

    useEffect(() => {
        fetchData();
    }, [activeTab]);
    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            event.preventDefault();
            event.stopPropagation();
            setValidated(false);
            setDisabled(true);

            const postData = new FormData();
            postData.append("desc", formData.desc.trim());
            postData.append("postJobsId", formData.postJobsId);
            if (formData.resume) {
                const allowedExts = ['pdf', 'xls', 'xlsx'];
                const fileExt = formData.resume.name.split('.').pop().toLowerCase();
                console.log("File Extension:", fileExt); 

                if (!allowedExts.includes(fileExt)) {
                    setErrorMessage("Invalid file type. Only PDF or Docx allowed.");
                    setDisabled(false);
                    return;
                }

                postData.append("resume", formData.resume);
            } else {
                setErrorMessage("Please upload a resume.");
                setDisabled(false);
                return;
            }
            try {
                const response = await applyJobsPostAction(postData);

                if (response.code === 200) {
                    setSuccessMessage("Application submitted successfully!");
                    setErrorMessage('');

                    setFormData({
                       
                        desc: '',
                        postJobsId: '',
                        resume: null
                    });
                    setTimeout(() => {
                        setSuccessMessage('');
                        setShowModal(false);
                    }, 2000);
                    await fetchData();
                } else {
                    setErrorMessage(response.msg);
                    setSuccessMessage('');
                }
            } catch (error) {
                setErrorMessage("Error submitting application. Please try again.");
                setSuccessMessage('');
                console.error("Error submitting application:", error);
            } finally {
                setDisabled(false);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === 'desc') {
            if (!value.trim()) {
                setErrorMessage("Description cannot be blank or contain only spaces.");
            } else {
                setErrorMessage(''); 
            }
        }
    };
    // const fileChangedHandler = (event, elename) => {
    //     event.preventDefault();
    //     const file = event.target.files[0];

    //     if (!file) return; 
    //     const allowedExts = ['pdf', 'xls', 'xlsx'];
    //     const fileExt = file.name.split('.').pop().toLowerCase();
    //     if (!allowedExts.includes(fileExt)) {
    //         setErrorMessage("Only PDF or Docx allowed.");
    //         setFormErrors((prevErrors) => ({
    //             ...prevErrors,
    //             [elename]: "",
    //         }));
    //         return;
    //     }

    //     const maxFileSizeKB = 1024; 
    //     if (file.size > maxFileSizeKB * 1024) {
    //         setErrorMessage(`File size must be less than ${maxFileSizeKB} KB.`);
    //         setFormErrors((prevErrors) => ({
    //             ...prevErrors,
    //             [elename]: `File size too large. Please upload a file smaller than ${maxFileSizeKB} KB.`,
    //         }));
    //         return;
    //     }

    //     setErrorMessage('');
    //     setFormErrors((prevErrors) => ({
    //         ...prevErrors,
    //         [elename]: '',
    //     }));
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [elename]: file,
    //     }));
    // };



    const fileChangedHandler = (event, elename) => {
        event.preventDefault();
        const file = event.target.files[0];
      
        setErrorMessage('');
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [elename]: '',
        }));
      
        if (!file) return;
      
        const allowedExts = ['pdf', 'xls', 'xlsx'];
        const fileExt = file.name.split('.').pop().toLowerCase();
      
        if (!allowedExts.includes(fileExt)) {
          setErrorMessage("");
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [elename]: "",
          }));
      
          setFormData((prevData) => ({
            ...prevData,
            [elename]: null,
          }));
      
          event.target.value = null;
          return;
        }
      
        const maxFileSizeKB = 1024;
        if (file.size > maxFileSizeKB * 1024) {
          setErrorMessage(`File size must be less than ${maxFileSizeKB} KB.`);
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [elename]: `File size too large. Please upload a file smaller than ${maxFileSizeKB} KB.`,
          }));
      
          setFormData((prevData) => ({
            ...prevData,
            [elename]: null,
          }));
      
          event.target.value = null;
          return;
        }
      
        setFormData((prevData) => ({
          ...prevData,
          [elename]: file,
        }));
      };
      

    const handleCloseModal = () => {
        setShowModal(false);
        setSuccessMessage('');
        setErrorMessage('');
        setFormErrors('');
        setValidated(false);
        setFormData({
            desc: '',
            postJobsId: '',
            resume: null
        });
    };

    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        fetchData();
    }, [activeTab]);

    useEffect(() => {
        // fetchData();
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            {status === 1 ? (
                <Headerlogin />
            ) : (
                <HomeHeader />
            )}
            <section className="job-section">
                <Container className="search-bar" >
                    <Row style={{ marginTop: status === 1 ? '50px' : '150px', paddingBottom: '20px' }}>
                        <Col md={8} >
                            <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
                                <Nav variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="allJobs">All Jobs</Nav.Link>
                                    </Nav.Item>
                                    {status === 1 && (
                                        <>
                                            <Nav.Item>
                                                <Nav.Link eventKey="myPosting">My Postings</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="appliedJobs">Applied Jobs</Nav.Link>
                                            </Nav.Item>
                                        </>
                                    )}
                                </Nav>
                            </Tab.Container>

                        </Col>

                    </Row>
                </Container>
                <Container className="containerHeight">
                    {loading ? (
                        <div className="loader-container">
                            <Spinner animation="border" style={{ color: "#ff5733" }} />
                        </div>
                    ) : (
                        <>
                            {activeTab === "allJobs" && (
                                <div className="card-grid mt-3">
                                    {allJobsData.length > 0 ? (
                                        allJobsData.map((item, index) => (
                                            <Card key={index} className="card-custom">
                                                <div className="job-rating-card">
                                                    <div className="rating-badge">
                                                        {/* Add your rating badge here if needed */}
                                                    </div>
                                                </div>
                                                <Card.Body className="d-flex flex-column" style={{ padding: '1px 10px 15px 10px' }}>
                                                    <div className="card-content flex-grow-1">
                                                        <Card.Title>
                                                            {item.jobName.length > 25
                                                                ? `${item.jobName.substring(0, 25)}...`
                                                                : item.jobName}
                                                        </Card.Title>
                                                        <Card.Subtitle className="mb-2">
                                                            <i className="fas fa-thin fa-film" style={{ color: "red", marginRight: "5px" }}></i>
                                                            {item.companyName.length > 20
                                                                ? `${item.companyName.substring(0, 20)}...`
                                                                : item.companyName}
                                                        </Card.Subtitle>
                                                        <Card.Text>
                                                            <i className="fas fa-map-marker-alt" ></i><span title={item.location}>{item.location}</span>
                                                            <br />
                                                            <span style={{ marginLeft: '10px', marginTop: '5px', display: 'inline-block' }} title={item.desc}>
                                                                {/* Desc: {item.desc} */}
                                                                Desc: {item.desc.length > 25 ? `${item.desc.substring(0,25)}...`: item.desc}
                                                            </span>
                                                        </Card.Text>
                                                    </div>
                                                    <div className="button-group mt-2">
                                                        {item.loginUserApplyJobsStatus ? (
                                                            <button
                                                                className="btn"
                                                                disabled
                                                                style={{
                                                                    backgroundColor: '#6c757d',
                                                                    color: '#FFFFFF',
                                                                    fontSize: '12px'
                                                                }}
                                                            >
                                                                Applied
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="btn btnColor"
                                                                onClick={() => {
                                                                    if (status === 1) {
                                                                        handleApplyNow(item._id);
                                                                    } else {
                                                                        // handleOpenApplyModal();
                                                                        navigate(`${process.env.PUBLIC_URL}/loginWithOtp`);

                                                                    }
                                                                }}
                                                                style={{

                                                                    fontSize: '12px'
                                                                }}
                                                            >
                                                                Apply Now
                                                            </button>
                                                        )}

                                                        <button

                                                            className="btn createbtnColor"
                                                            onClick={() => openJobDetailsModal(item)}
                                                            style={{
                                                                fontSize: '12px'
                                                            }}
                                                        >
                                                            View
                                                        </button>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        ))
                                    ) : (
                                        <div className="no-data">
                                            <p style={{ fontSize: '19px' }}>No jobs available at the moment.</p>
                                        </div>
                                    )}

                                </div>
                            )}

                            {activeTab === "myPosting" && (
                                <>
                                    <Link className="btn btn-sm btnColor ms-3" to={`${process.env.PUBLIC_URL}/user/addJob`} >Add Job</Link>
                                    <div className="card-grid mt-3">
                                        {/* Render content for "myPosting" tab */}
                                        {myPostingData.length > 0 ? (
                                            myPostingData.map((item, index) => (
                                                <Card key={index} className="card-custom">
                                                    <div className="job-rating-card">
                                                        <div className="rating-badge">
                                                            {/* Add your rating badge here if needed */}
                                                        </div>
                                                    </div>
                                                    <Card.Body className="d-flex flex-column" style={{ padding: '1px 10px 15px 10px' }}>
                                                        <div className="card-content flex-grow-1">
                                                            <Card.Title>
                                                                {item.jobName.length > 25
                                                                    ? `${item.jobName.substring(0, 25)}...`
                                                                    : item.jobName}
                                                            </Card.Title>
                                                            <Card.Subtitle className="mb-2">
                                                                <i className="fas fa-thin fa-film" style={{ color: "red", marginRight: "5px" }}></i>
                                                                {item.companyName.length > 20
                                                                    ? `${item.companyName.substring(0, 20)}...`
                                                                    : item.companyName}
                                                            </Card.Subtitle>
                                                            <Card.Text>
                                                                <i className="fas fa-map-marker-alt"></i>
                                                                <span title={item.location}>{item.location}</span>
                                                                <br />
                                                                <span style={{ marginLeft: '10px', marginTop: '5px', display: 'inline-block' }} title={item.desc}>
                                                                    {/* Desc: {item.desc} */}
                                                                    Desc: {item.desc.length > 25 ? `${item.desc.substring(0, 25)}...`: item.desc}
                                                                </span>
                                                            </Card.Text>
                                                        </div>
                                                        <div className="button-group mt-2">
                                                            <button
                                                                className="btn btnColor"
                                                                onClick={() => {
                                                                    if (status === 1) {
                                                                        openModalWithJobData(item._id);
                                                                    } else {
                                                                        // handleOpenApplyModal();
                                                                        navigate(`${process.env.PUBLIC_URL}/loginWithOtp`);

                                                                    }
                                                                }}
                                                                style={{
                                                                    fontSize: '12px'
                                                                }}
                                                            >
                                                                See Applicants
                                                            </button>
                                                        </div>
                                                    </Card.Body>
                                                </Card>

                                            ))
                                        ) : (
                                            <div className="no-data">
                                                <p>No posts available.</p>
                                            </div>
                                        )}
                                        <PostApplyDetailsModel isOpen={modalOpen} toggle={toggleModal} job={selectedJob} />
                                    </div>
                                </>
                            )}

                            {activeTab === "appliedJobs" && (
                                <div className="card-grid mt-3">
                                    {/* Render content for "appliedJobs" tab */}
                                    {appliedJobsData.length > 0 ? (
                                        appliedJobsData.map((item, index) => (
                                            <Card key={index} className="card-custom">
                                                <div className="job-rating-card">
                                                    <div className="rating-badge">
                                                        {/* Add your rating badge here if needed */}
                                                    </div>
                                                </div>
                                                <Card.Body className="d-flex flex-column" style={{ padding: '1px 10px 15px 10px' }}>
                                                    <div className="card-content flex-grow-1">
                                                        <Card.Title>
                                                            {item.jobName.length > 25
                                                                ? `${item.jobName.substring(0, 25)}...`
                                                                : item.jobName}
                                                        </Card.Title>
                                                        <Card.Subtitle className="mb-2">
                                                            <i className="fas fa-thin fa-film" style={{ color: "red", marginRight: "5px" }}></i>
                                                            {item.companyName.length > 20
                                                                ? `${item.companyName.substring(0, 20)}...`
                                                                : item.companyName}   
                                                        </Card.Subtitle>
                                                        <Card.Text>
                                                            <i className="fas fa-map-marker-alt"></i>
                                                            <span title={item.location}>{item.location}</span>
                                                            <br />
                                                            <span style={{ marginLeft: '10px', marginTop: '5px', display: 'inline-block' }} title={item.desc}>
                                                                {/* Desc: {item.desc} */}
                                                                Desc: {item.desc.length > 25 ? `${item.desc.substring(0,25)}...`: item.desc}
                                                            </span>
                                                        </Card.Text>
                                                    </div>
                                                    <div className="button-group mt-2">

                                                        <button
                                                            className="btn"
                                                            disabled
                                                            style={{
                                                                backgroundColor: '#6c757d',
                                                                color: '#FFFFFF',
                                                                fontSize: '12px'
                                                            }}
                                                        >
                                                            Applied
                                                        </button>
                                                        {console.log('99999999', item)}
                                                        <button

                                                            className="btn btnColor"
                                                            onClick={() => openJobDetailsModal(item)}
                                                            style={{
                                                                fontSize: '12px'
                                                            }}
                                                        >
                                                            View
                                                        </button>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        ))
                                    ) : (
                                        <div className="no-data">
                                            <p>No applied jobs available.</p>
                                        </div>
                                    )}
                                </div>
                            )}
                            {isJobDetailsModalOpen && selectedJobDetails && (
                                <JobDetailsModel
                                    isOpen={isJobDetailsModalOpen}
                                    toggle={closeJobDetailsModal}
                                    jobDetails={selectedJobDetails}
                                />
                            )}
                        </>
                    )}
                </Container>
            </section>
            {/* {status === 1 ? ( */}
            <></>
            {/* ) : ( */}
            <>
                <HomeFooter />
                <SubFooter />
            </>
            {/* ) */}
            {/* } */}
            <Modal show={showModal} backdrop="static" onHide={handleCloseModal} centered>
                <Modal.Header className='useModelHeader' closeButton>
                    <Modal.Title>Apply for {formData.jobPosition}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='liteModal-content'>
                    <Form className="userForm" noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group controlId="formResume" className="mt-3">
                            <Form.Label className="allTextColor mb-2">Upload Resume (PDF)</Form.Label>
                            <Form.Control
                                type="file"
                                name="resume"
                                onChange={(event) => fileChangedHandler(event, "resume")}
                                accept=".pdf,.xls,.xlsx"
                                required
                               
                            />
                            {formErrors.resume && (
                                <div className="text-danger mt-2">{formErrors.resume}</div>
                            )}
                            <Form.Control.Feedback type="invalid">
                                Please upload a valid resume in PDF or Docx.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formDesc" className="mt-3">
                            <Form.Label className="allTextColor mb-2">Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="desc"
                                value={formData.desc}
                                onChange={handleInputChange}
                                placeholder="Enter a description"
                                autocomplete="off"
                                pattern=".*\S+.*"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Description.
                            </Form.Control.Feedback>
                        </Form.Group>

                        {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}

                        {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
                        <div className="mt-4">
                            <Button className="btnColor" onClick={handleCloseModal}>
                                Close
                            </Button>{' '}
                            <Button className="createbtnColor" type="submit" style={{ marginLeft: '5px' }} >
                                Submit Application
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
              
            </Modal>
     
        </Fragment >
    );
};

export default Job;
