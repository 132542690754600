import React, { memo, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../../assets/images/frontPageImg/blackLogo.png";
import userImg from "../../../assets/images/frontPageImg/userImg2.png";
import { Nav, Dropdown, Modal } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import ProfileEdit from "../Pages/ProfileEdit";
import { imgPath } from "../../../admin/common/function";
import { FaBell } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  getNotificationListDataAction,
  updateReadStatusDataAction,
  userDeleteAction,
  userLogoutAction,
} from "../../../action/Admin.action";
import { toast } from "react-toastify";
import NotificationList from "../NotificationList";
import { setAlert } from "../../../slices/home";
import Swal from "sweetalert2";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [isClearDisabled, setIsClearDisabled] = useState(true);

  useEffect(() => {
    setIsClearDisabled(dataList.length === 0);
  }, [dataList]);

  const getDataList = async () => {
    const resp = await getNotificationListDataAction();
    if (resp.code === 200) {
      setDataList(resp.data);
    }
  };

  useEffect(() => {
    getDataList();
  }, []);

  const handleOpenProfileModal = () => setShowProfileModal(true);
  const handleCloseProfileModal = () => setShowProfileModal(false);

  const toggleNotifications = async () => {
    setShowNotifications(!showNotifications);
  };

  const handleClearAll = async () => {
    const response = await updateReadStatusDataAction();
    if (response.code === 200) {
      // getDataList();
      setDataList([]);
      setShowNotifications(false);
    }
  };

  const handleCategoryNavigation = () => {
    navigate(`${process.env.PUBLIC_URL}/user/category`);
  };
  const status = JSON.parse(localStorage.getItem("status"));
  const loginDb = JSON.parse(localStorage.getItem("login"));

  // const handleLogout = () => {
  //   localStorage.clear();

  //   setTimeout(() => {
  //     window.location.href = "/";
  //   });
  // };

  const handleLogout = async () => {
    try {
      const resp = await userLogoutAction();

      if (resp.code === 200) {
        localStorage.clear();

        setTimeout(() => {
          window.location.href = "/";
        }, 100);
      } else {
        console.error("Logout failed:", resp.message);
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const getTimeDifference = (createdAt) => {
    const createdTime = new Date(createdAt);
    const currentTime = new Date();
    const diffInSeconds = Math.floor((currentTime - createdTime) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} sec ago`;
    } else if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)} min ago`;
    } else if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)} hr ago`;
    } else {
      return `${Math.floor(diffInSeconds / 86400)} day ago`;
    }
  };

  const handleNavigate = () => {
    navigate(`${process.env.PUBLIC_URL}/allNotifications`);
  };

  const _delete = async () => {
    Swal.fire({
      title: "Confirm to Delete",
      text: "Are you sure you want to delete your account? Once deleted, all your data will be permanently erased and cannot be recovered.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "swal-confirm-btn",
        cancelButton: "swal-cancel-btn",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(
          setAlert({
            open: false,
            severity: "success",
            msg: "Loading...",
            type: "loader",
          })
        );

        const resp = await userDeleteAction();
        if (resp.code === 200) {
          toast.success("You have successfully deleted your account.", {
            autoClose: 2000,
          });
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = "/";
        }
      }
    });
  };

  return (
    <Navbar expand="lg" className="theme-header navbar-overlay1">
      <Container fluid>
        <Navbar.Brand className="mobilelogo">
          {status === 1 ? (
            <Link to={`${process.env.PUBLIC_URL}/user/dashboard`}>
              <img src={Logo} alt="" style={{ height: "65px" }} />
            </Link>
          ) : (
            <Link to={`${process.env.PUBLIC_URL}/`}>
              <img src={Logo} alt="" style={{ height: "65px" }} />
            </Link>
          )}
        </Navbar.Brand>
        {status === 1 && (
          <>
            <Navbar.Toggle aria-controls="navbar-nav" />
            <Navbar.Collapse id="navbar-nav" className="justify-content-end">
              <Nav
                className="ml-auto nav-links loginNavLinkCOlor"
                style={{ marginRight: "30px", gap: "15px" }}
              >
                <Nav.Link
                  onClick={() => navigate(`${process.env.PUBLIC_URL}/`)}
                  className={
                    location.pathname === `${process.env.PUBLIC_URL}/`
                      ? "active"
                      : ""
                  }
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  onClick={() =>
                    navigate(`${process.env.PUBLIC_URL}/user/dashboard`)
                  }
                  className={
                    location.pathname ===
                    `${process.env.PUBLIC_URL}/user/dashboard`
                      ? "active"
                      : ""
                  }
                >
                  Dashboard
                </Nav.Link>
                <Nav.Link
                  onClick={() =>
                    navigate(`${process.env.PUBLIC_URL}/user/category`)
                  }
                  className={
                    location.pathname ===
                    `${process.env.PUBLIC_URL}/user/category`
                      ? "active"
                      : ""
                  }
                >
                  Category
                </Nav.Link>
                <Nav.Link
                  onClick={() => navigate(`${process.env.PUBLIC_URL}/job`)}
                  className={
                    location.pathname === `${process.env.PUBLIC_URL}/job`
                      ? "active"
                      : ""
                  }
                >
                  Jobs
                </Nav.Link>
              </Nav>

              {/* Notification Bell Icon with Badge */}
              <div className="notification-wrapper">
                <FaBell
                  size={20}
                  className="notification-icon"
                  onClick={toggleNotifications}
                />
                {dataList.length > 0 && (
                  <span className="notification-badge">{dataList.length}</span>
                )}

                {showNotifications && (
                  <>
                    <div className="notification-arrow"></div>

                    <div className="notification-dropdown">
                      <div className="notification-header">
                        <span>Notifications</span>
                        <span className="notification-count">
                          {dataList.length}
                        </span>
                        <IoIosCloseCircleOutline
                          size={20}
                          className="close-icon"
                          onClick={toggleNotifications}
                        />
                      </div>

                      <NotificationList
                        dataList={dataList}
                        getTimeDifference={getTimeDifference}
                        handleNavigate={handleNavigate}
                        handleClearAll={handleClearAll}
                        isClearDisabled={isClearDisabled}
                      />

                      <div className="notification-footer">
                        <button className="btn_view" onClick={handleNavigate}>
                          View All
                        </button>
                        <button
                          className="btn_clear"
                          disabled={isClearDisabled}
                          onClick={handleClearAll}
                          style={{
                            backgroundColor:
                              dataList.length === 0 ? "#ccc" : "#72ADAE",
                            cursor:
                              dataList.length === 0 ? "not-allowed" : "pointer",
                          }}
                        >
                          Clear All
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <Dropdown className="static-profile-menu">
                <Dropdown.Toggle
                  variant="none"
                  className="static-profile-toggle-btn"
                  id="dropdown-custom-components"
                  bsPrefix="static-profile-toggle-custom"
                >
                  {loginDb?.userProfile ? (
                    <img
                      src={imgPath(loginDb.userProfile)}
                      alt="Profile"
                      className="static-profile-toggle-img"
                    />
                  ) : loginDb?.fullName &&
                    loginDb.fullName !== "User" &&
                    loginDb.fullName.trim() !== "" ? (
                    <div className="static-profile-initials">
                      {loginDb.fullName
                        .split(" ")
                        .map((n) => n[0])
                        .join("")
                        .toUpperCase()}
                    </div>
                  ) : loginDb?.businessContactName ? (
                    <div className="static-profile-initials">
                      {loginDb.businessContactName
                        .split(" ")
                        .map((n) => n[0])
                        .join("")
                        .toUpperCase()}
                    </div>
                  ) : (
                    <img
                      src={userImg}
                      alt="Default Profile"
                      className="static-profile-toggle-img"
                    />
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu align="end" className="static-profile-dropdown">
                  <div className="static-dropdown-header">
                    {loginDb?.userProfile ? (
                      <img
                        src={imgPath(loginDb.userProfile)}
                        alt="Profile"
                        className="static-profile-toggle-img"
                      />
                    ) : loginDb?.fullName &&
                      loginDb.fullName !== "User" &&
                      loginDb.fullName.trim() !== "" ? (
                      <div className="static-profile-initials">
                        {loginDb.fullName
                          .split(" ")
                          .map((n) => n[0])
                          .join("")
                          .toUpperCase()}
                      </div>
                    ) : loginDb?.businessContactName ? (
                      <div className="static-profile-initials">
                        {loginDb.businessContactName
                          .split(" ")
                          .map((n) => n[0])
                          .join("")
                          .toUpperCase()}
                      </div>
                    ) : (
                      <img
                        src={userImg}
                        alt="Default Profile"
                        className="static-profile-toggle-img"
                      />
                    )}

                    <div className="static-dropdown-user-info">
                      <span className="static-dropdown-user-name">
                        {loginDb.fullName &&
                        loginDb.fullName !== "" &&
                        loginDb.fullName !== "User"
                          ? loginDb.fullName.length > 10
                            ? `${loginDb.fullName.substring(0, 10)}...`
                            : loginDb.fullName
                          : loginDb.businessContactName
                          ? loginDb.businessContactName.length > 10
                            ? `${loginDb.businessContactName.substring(
                                0,
                                10
                              )}...`
                            : loginDb.businessContactName
                          : ""}
                      </span>
                      <span className="static-dropdown-user-email">
                        {loginDb?.email}
                      </span>
                      <span className="static-dropdown-user-phone">
                        {loginDb?.phone}
                      </span>
                    </div>
                  </div>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    className="static-dropdown-item text-start"
                    onClick={handleOpenProfileModal}
                  >
                    User Profile
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    className="static-dropdown-item text-start"
                    onClick={(e) => _delete()}
                  >
                    Delete Account
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    className="static-dropdown-item text-start"
                    onClick={handleLogout}
                  >
                    Log out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Navbar.Collapse>
            <Modal
              show={showProfileModal}
              onHide={handleCloseProfileModal}
              size="lg"
              aria-labelledby="profile-modal-title"
              centered
            >
              <Modal.Header
                className="editProfielCardHeader custom-modal-header"
                closeButton
              >
                <Modal.Title
                  id="profile-modal-title"
                  className="editProfielCardHeader"
                >
                  Edit Profile
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="editProfielCard">
                <ProfileEdit onClose={handleCloseProfileModal} />
              </Modal.Body>
            </Modal>
          </>
        )}
      </Container>
    </Navbar>
  );
}

export default memo(Header);
