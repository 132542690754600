import React, { useState, useEffect, memo } from 'react';
import { Col, Row, Form, Button, Card } from "react-bootstrap";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { addEditCategoryAction } from "../../action/Admin.action";
import { getCategoryDataAction } from '../../action/Admin.action';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../slices/home';
import { PermissionList } from '../../admin/common/constant';

const AddEdit = (props) => {

  let navigate = useNavigate();
  let { state } = useLocation();

  const dispatch = useDispatch();
  const [isDisabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({ permission: [] });
  const [dataList, setDataList] = useState([]);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {

      event.preventDefault();
      event.stopPropagation();
      setValidated(true);

    } else {
      event.preventDefault();
      event.stopPropagation();
      //setValidated(false);
      setDisabled(true);
      let msg = "";
      if (formData._id) {
        msg = "You have successfully Category updated.";
      } else {
        msg = "You have successfully added new Category.";
      }
      let resp = await addEditCategoryAction(formData);

      if (resp.code === 200) {

        dispatch(setAlert({ open: true, severity: "success", msg: msg, type: '' }));
        setTimeout(() => {
          navigate({ pathname: "/admin/category", state: "" });
        }, 2000);
      } else {
        setDisabled(false);
        dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
      }
      return false;
    }
  };
  const getDataList = async () => {
    const resp = await getCategoryDataAction();
    if (resp.code === 200) {
      setDataList(resp.data);
    }
  }

  useEffect(() => {
    getDataList();
  }, [])


  const cancelBtn = () => { navigate({ pathname: `${process.env.PUBLIC_URL}/admin/category`, state: "" }) }

  const handleChange = (post) => {

    let name = post.name;
    let event = post.event;
    let from = { ...formData };

    if (post.fieldType === "permission") {
      let index = from.permission.indexOf(post.value);
      if (index > -1) {
        from.permission.splice(index, 1);
      } else {
        from.permission.push(post.value);
      }
    } else {
      from[name] = event.target.value;
    }

    setFormData({ ...formData, ...from });

  }

  useEffect(() => {

    if (state && state._id) {

      let data = state;
      let editData = {
        _id: data._id,
        name: data.name,
        status: data.status,
        categoryId: data.categoryId
      }
      setFormData(editData);
    }

  }, []);

  return (
    <div className='mt-5 pt-2'>
      <Card>
        <Card.Body className="crdBdy">
          <div className="Role-page text-start companyhed mt-3 pt-2">
            <Row className="justify-content-center" >
              <Col md={9} lg={9}>
                <h6 className='crdHd1'>{formData._id ? 'Update Location Category/ Subcategory' : 'Add Location Category Or Subcategory'}</h6>
              </Col>
              <Col md={3} lg={3} className="text-right">
                <Link to={`${process.env.PUBLIC_URL}/admin/category`} className="text-white btn savebtn pull-right vndrBtn">Back</Link>
              </Col>
            </Row>
            <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
              <div className="mt-4">
                <Row>
                  <Col xs="12" lg="4">
                    <div className="mb-3">
                      <Form.Label>Name<span className="star"> *</span></Form.Label>
                      <Form.Control type="text" pattern="^(?!.* {2})([A-Za-z0-9,\/\\ \-]+)$" maxLength={32} minLength={2} placeholder="Category Name" className="filedbg cmnsz" value={formData.name ? formData.name : ""} onChange={e => handleChange({ name: 'name', event: e })} required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Name.
                      </Form.Control.Feedback>
                    </div>
                  </Col>
                  <Col xs="12" lg="4">
                    <div className="mb-3">
                      <Form.Label>Parent Category Name<span className="star"></span></Form.Label>

                      <Form.Select aria-label="Default select example" className="filedbg cmnsz" value={formData.categoryId ? formData.categoryId : ""} onChange={e => handleChange({ name: 'categoryId', event: e })} >
                        <option value="000000000000000000000000">Select Parent Category</option>
                        {dataList && dataList.length > 0 &&
                          dataList.map((item, index) => {
                            if (item.categoryId == "000000000000000000000000") {
                              return <option value={item._id} key={index}>{item.name}</option>
                            } <div className="Role-page text-start companyhed mt-3 pt-2">
                              <Row className="justify-content-center" >
                                <Col md={9} lg={9}>
                                  <h3>{formData._id ? 'Update Location Category/ Subcategory' : 'Add Location Category Or Subcategory'}</h3>
                                </Col>
                                <Col md={3} lg={3} className="text-right">
                                  <Link to={`${process.env.PUBLIC_URL}/admin/category`} className="text-white btn savebtn pull-right vndrBtn">Back</Link>
                                </Col>
                              </Row>
                              <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                                <div className="mt-4">
                                  <Row>
                                    <Col xs="12" lg="4">
                                      <div className="mb-3">
                                        <Form.Label>Name<span className="star"> *</span></Form.Label>
                                        <Form.Control type="text" pattern="^(?!.* {2})([A-Za-z0-9,\/\\ \-]+)$" maxLength={32} minLength={2} placeholder="Category Name" className="filedbg cmnsz" value={formData.name ? formData.name : ""} onChange={e => handleChange({ name: 'name', event: e })} required />
                                        <Form.Control.Feedback type="invalid">
                                          Please provide a valid Name.
                                        </Form.Control.Feedback>
                                      </div>
                                    </Col>
                                    <Col xs="12" lg="4">
                                      <div className="mb-3">
                                        <Form.Label>Parent Category Name<span className="star"></span></Form.Label>

                                        <Form.Select aria-label="Default select example" className="filedbg cmnsz" value={formData.categoryId ? formData.categoryId : ""} onChange={e => handleChange({ name: 'categoryId', event: e })} >
                                          <option value="000000000000000000000000">Select Parent Category</option>
                                          {dataList && dataList.length > 0 &&
                                            dataList.map((item, index) => {
                                              if (item.categoryId == "000000000000000000000000") {
                                                return <option value={item._id} key={index}>{item.name}</option>
                                              }
                                            })}
                                        </Form.Select>
                                      </div>
                                    </Col>
                                    {/* <Col xs="12" lg="4">
                                      <div className="mb-3">
                                        <Form.Label>Listed Status<span className="star"></span></Form.Label>
                                        <Form.Select aria-label="Default select example" className="filedbg cmnsz" value={formData.status > -1 ? formData.status : ""} onChange={e => handleChange({ name: 'status', event: e })} required>
                                          <option value="">Select Status</option>
                                          <option value="1">Active</option>
                                          <option value="0">Inactive</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                          Please provide a valid Listed Status.
                                        </Form.Control.Feedback>
                                      </div>   
                                    </Col> */}
                                  </Row>
                                  <Button type='submit' className="savebtn" size="lg" disabled={isDisabled}>Save</Button>{' '}
                                  <Button className="cancelbtn" onClick={cancelBtn} size="lg">Cancel</Button>
                                </div>
                              </Form>
                            </div>
                          })}
                      </Form.Select>
                    </div>
                  </Col>
                  {/* <Col xs="12" lg="4">
                    <div className="mb-3">
                      <Form.Label>Listed Status<span className="star"></span></Form.Label>
                      <Form.Select aria-label="Default select example" className="filedbg cmnsz" value={formData.status > -1 ? formData.status : ""} onChange={e => handleChange({ name: 'status', event: e })} required>
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Listed Status.
                      </Form.Control.Feedback>
                    </div>
                  </Col> */}
                </Row>
                <Button type='submit' className="vndrBtn" size="lg" disabled={isDisabled}>Save</Button>{' '}
                <Button className="vndrBtn" onClick={cancelBtn} size="lg">Cancel</Button>
              </div>
            </Form>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default memo(AddEdit);

