import React, { useState, useEffect, memo } from "react";
import { Col, Row, Form, Button, Card } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { addEditSettingDataAction } from "../../action/Admin.action";
import { getCategoryVendorDataAction } from "../../action/Admin.action";
import { useDispatch } from "react-redux";
import { setAlert } from "../../slices/home";
import { imgPath, PermissionList } from "../../admin/common/constant";

const AddEdit = (props) => {
    let navigate = useNavigate();
    const location = useLocation();
    const state = location.state?.item || {};

    const dispatch = useDispatch();
    const [isDisabled, setDisabled] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({ permission: [] });
    const [imageFile, setImageFile] = useState(null);
    const [leftImagePreview, setLeftImagePreview] = useState(null);
    const [rightImagePreview, setRightImagePreview] = useState(null);
    const [institutesImagePreview, setInstitutesImagePreview] = useState(null);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);
            let msg = "";
            if (formData._id) {
                msg = "You have successfully Category updated.";
            } else {
                msg = "You have successfully added new Category.";
            }

            const formDataToSend = new FormData();
            for (let key in formData) {
                if (formData[key] instanceof File) {
                    formDataToSend.append(key, formData[key]);
                } else {
                    formDataToSend.append(key, formData[key]);
                }
            }

            if (imageFile) {
                formDataToSend.append("categoryImg", imageFile); // Append regular image file
            }

            let resp = await addEditSettingDataAction(formDataToSend);

            if (resp.code === 200) {
                dispatch(
                    setAlert({ open: true, severity: "success", msg: msg, type: "" })
                );
                setTimeout(() => {
                    navigate({
                        pathname: `${process.env.PUBLIC_URL}/admin/setting`,
                        state: "",
                    });
                }, 2000);
            } else {
                setDisabled(false);
                dispatch(
                    setAlert({ open: true, severity: "danger", msg: resp.msg, type: "" })
                );
            }
            return false;
        }
    };

    const cancelBtn = () => {
        navigate({
            pathname: `${process.env.PUBLIC_URL}/admin/setting`,
            state: "",
        });
    };

    const handleChange = (post) => {
        let name = post.name;
        let event = post.event;
        let from = { ...formData };

        if (post.fieldType === "permission") {
            let index = from.permission.indexOf(post.value);
            if (index > -1) {
                from.permission.splice(index, 1);
            } else {
                from.permission.push(post.value);
            }
        } else {
            from[name] = event.target.value;
        }

        setFormData({ ...formData, ...from });
    };
    const handleFileChange = (e, type) => {
        const file = e.target.files[0];
        const fileUrl = URL.createObjectURL(file);

        if (type === "sectionLeft") {
            setFormData({ ...formData, sectionLeft: file });
            setLeftImagePreview(fileUrl);
        } else if (type === "sectionRight") {
            setFormData({ ...formData, sectionRight: file });
            setRightImagePreview(fileUrl);
        } else if (type === "institutesImg") {
            setFormData({ ...formData, institutesImg: file });
            setInstitutesImagePreview(fileUrl);
        }
    };

    useEffect(() => {
        if (state?._id) {
            console.log("State received:", state);
            setFormData({
                _id: state._id,
                sectionRight: state?.sectionRight || "",
                sectionLeft: state?.sectionLeft || "",
                institutesImg: state?.institutesImg || "",
            });

            // Set image previews only if images exist
            setLeftImagePreview(state?.sectionLeft ? imgPath(state.sectionLeft) : null);
            setRightImagePreview(state?.sectionRight ? imgPath(state.sectionRight) : null);
            setInstitutesImagePreview(state?.institutesImg ? imgPath(state.institutesImg) : null);
        }
    }, [state]);

    return (
        <div className="mt-5 pt-2">
            <Card>
                <Card.Body className="crdBdy">
                    <div className="Role-page text-start companyhed mt-3 pt-2">
                        <Row className="justify-content-center">
                            <Col md={9} lg={9}>
                                <h6 className="crdHd1">
                                    {formData._id
                                        ? "Update Setting"
                                        : "Add Setting"}
                                </h6>
                            </Col>
                            <Col md={3} lg={3} className="text-right">
                                <Link
                                    to={`${process.env.PUBLIC_URL}/admin/setting`}
                                    className="text-white btn savebtn pull-right"
                                >
                                    Back
                                </Link>
                            </Col>
                        </Row>
                        <Form
                            noValidate
                            validated={validated}
                            onSubmit={(e) => handleSubmit(e)}
                        >
                            <div className="mt-4">
                                <Row>
                                    {/* Left Section Image */}
                                    <Col xs="12" lg="4">
                                        <div className="mb-3">
                                            <Form.Label>
                                                Section Left Image <span className="star"></span>
                                            </Form.Label>
                                            <Form.Control
                                                type="file"
                                                className="filedbg cmnsz"
                                                onChange={(e) => handleFileChange(e, "sectionLeft")}
                                            />
                                            {leftImagePreview && (
                                                <div className="preview-container" style={{
                                                    marginTop: '10px',
                                                    textAlign: 'center'
                                                }}>
                                                    <img
                                                        src={leftImagePreview}
                                                        alt="Section Left Preview"
                                                        className="preview-img"
                                                        style={{
                                                            maxWidth: '100%',
                                                            height: '85px',
                                                            borderRadius: '5px'
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <Form.Control.Feedback type="invalid">
                                                Please upload a valid image.
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>

                                    {/* Right Section Image */}
                                    <Col xs="12" lg="4">
                                        <div className="mb-3">
                                            <Form.Label>
                                                Section Right Image<span className="star"></span>
                                            </Form.Label>
                                            <Form.Control
                                                type="file"
                                                className="filedbg cmnsz"
                                                onChange={(e) => handleFileChange(e, "sectionRight")}
                                            />
                                            {rightImagePreview && (
                                                <div className="preview-container" style={{
                                                    marginTop: '10px',
                                                    textAlign: 'center'
                                                }}>
                                                    <img
                                                        src={rightImagePreview}
                                                        alt="Section Right Preview"
                                                        className="preview-img"
                                                        style={{
                                                            maxWidth: '100%',
                                                            height: '85px',
                                                            borderRadius: '5px'
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <Form.Control.Feedback type="invalid">
                                                Please upload a valid image.
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>

                                    {/* Institutes Image */}
                                    <Col xs="12" lg="4">
                                        <div className="mb-3">
                                            <Form.Label>
                                                Institutes Image <span className="star"></span>
                                            </Form.Label>
                                            <Form.Control
                                                type="file"
                                                className="filedbg cmnsz"
                                                onChange={(e) => handleFileChange(e, "institutesImg")}
                                            />
                                            {institutesImagePreview && (
                                                <div className="preview-container" style={{
                                                    marginTop: '10px',
                                                    textAlign: 'center'
                                                }}>
                                                    <img
                                                        src={institutesImagePreview}
                                                        alt="Institutes Image Preview"
                                                        className="preview-img"
                                                        style={{
                                                            maxWidth: '100%',
                                                            height: '85px',
                                                            borderRadius: '5px'
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <Form.Control.Feedback type="invalid">
                                                Please upload a valid image.
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                </Row>

                                <Button
                                    type="submit"
                                    className="vndrBtn"
                                    size="lg"
                                    disabled={isDisabled}
                                >
                                    Save
                                </Button>{" "}
                                <Button className="vndrBtn" onClick={cancelBtn} size="lg">
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default memo(AddEdit);
