import React, { Fragment, useEffect, useState } from "react";
import {
  getCategoryVendorDataAction,
  getPostJobsAction,
  getPostJobsListOtherUsersAction,
  getNewsDataAction,
  getSettingDataAction,
} from "../../../action/Admin.action";
import { setAlert } from "../../../slices/home";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import HomeHeader from "../Header/HomeHeader";
import searchIcon from "../../../assets/images/frontPageImg/interface.png";
import joblogo from "../../../assets/images/frontPageImg/joblogo.png";
import newsImg1 from "../../../assets/images/frontPageImg/newsImg1.png";
import homePageBannerImg from "../../../assets/images/frontPageImg/homePageBannerImg.jpg";

import {
  imgPath,
  objToArray,
  GetAllCountries,
  GetStatesOfCountry,
  GetCitiesOfState,
} from "../../../admin/common/function";
import HomeFooter from "../Footer/HomeFooter";
import HomeBackImgCarousel from "../Header/HomeBackImgCarousel";
import SubFooter from "../Footer/SubFooter";
import HeaderloginLite from "../Header/HeaderloginLite";

const Main = ({ selected }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [settingData, setSettingData] = useState([]);
  const status = JSON.parse(localStorage.getItem("status"));
  const [formData, setFormData] = useState({
    name: "",
    businessCountry: "IN",
    businessState: "",
    businessCity: "",
  });
  const [jobData, setJobData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [isScrollEnabled, setIsScrollEnabled] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(false);

  const fetchData = async () => {
    setAlert({
      open: true,
      severity: "success",
      msg: "Loading...",
      type: "loader",
    });

    const resp = await getCategoryVendorDataAction();

    setAlert({ open: false });

    if (resp && resp.code === 200) {
      setDataList(resp.data);
    } else {
      console.error("Error fetching data:", resp);
    }
  };

  const fetchSuggestions = async (name) => {
    if (name.length >= 1) {
      const resp = await getCategoryVendorDataAction({ name });
      if (resp && resp.code === 200) {
        setSuggestions(resp.data);
      } else {
        console.error("Error fetching suggestions:", resp);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleInputChange = async (e) => {
    const name = e.target.value;
    setFormData({ ...formData, name });
    setSelectedSuggestion(false); // Reset this when user types again
    await fetchSuggestions(name);
  };

  const handleSuggestionClick = (category) => {
    setFormData({ ...formData, name: category.name });
    setSuggestions([]);
    setSelectedSuggestion(true); // Mark as selected so "No match found" is hidden
    fetchData();
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (formData.name) {
      await fetchData();

      if (dataList.length > 0) {
        const selectedCategory = dataList.find(
          (item) => item.name.toLowerCase() === formData.name.toLowerCase()
        );

        if (selectedCategory) {
          const selectedId = selectedCategory._id;
          handleCardClick(selectedCategory);
        } else {
          console.error("No matching category found");
        }
      } else {
        console.error("No categories found");
      }
    }
  };
  const handleChange = (name, event) => {
    const value = event.target.value;
    let updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
  };

  const handleCardClick = (category) => {
    const { businessCountry, businessState, businessCity } = formData;

    navigate(`${process.env.PUBLIC_URL}/categoryProfileListing`, {
      state: {
        id: category,
        businessCountry,
        businessState,
        businessCity,
      },
    });
  };

  const getData = async () => {
    let resp;
    if (status === 1) {
      resp = await getPostJobsListOtherUsersAction();
    } else {
      resp = await getPostJobsAction();
    }
    if (resp.code === 200) {
      setJobData(resp.data);
    } else {
      console.error("Error fetching jobs:", resp.msg);
    }
  };

  const getNewsDataList = async () => {
    let resp = await getNewsDataAction();

    if (resp.code === 200) {
      setNewsData(resp.data);
    }
  };

  const getSettingDataList = async () => {
    let resp = await getSettingDataAction();

    if (resp.code === 200) {
      setSettingData(resp.data);
    }
  };

  const toggleScroll = (e) => {
    e.preventDefault();
    setIsScrollEnabled(!isScrollEnabled);
  };

  const toggleShowAll = () => {
    setShowAll((prevState) => !prevState);
  };

  // Determine the categories to display based on showAll state
  const displayedCategories = showAll ? dataList : dataList.slice(0, 18);

  console.log("data", displayedCategories);

  const handleViewAllClick = () => {
    // Navigate to the category-list page
    navigate(`${process.env.PUBLIC_URL}/job`);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  useEffect(() => {
    setFormData({
      ...formData,
      businessState: "",
      businessCity: "",
    });
  }, [formData.businessCountry]);

  useEffect(() => {
    getData();
    fetchData();
    getNewsDataList();
    getSettingDataList();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      {status === 1 ? (
        <>
          <HeaderloginLite />
          <HomeBackImgCarousel />
        </>
      ) : (
        <>
          <HomeHeader />
          <HomeBackImgCarousel />
        </>
      )}
      {/* <HomeHeader /> */}
      {/* <HomeBackImgCarousel /> */}
      <section className="search-bar-section">
        <Container className="search-bar mt-2">
          <Form onSubmit={handleSearch}>
            <Row className="g-2">
              <Col xs={12} md={2}>
                <Form.Group>
                  <Form.Select
                    aria-label="Default select example "
                    className="filedbg customC-select"
                    value={
                      formData.businessCountry ? formData.businessCountry : ""
                    }
                    onChange={(e) => handleChange("businessCountry", e)}
                  >
                    <GetAllCountries />
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid Country.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={2}>
                <Form.Group>
                  <Form.Select
                    aria-label="Default select example"
                    className="filedbg customC-select"
                    value={formData.businessState ? formData.businessState : ""}
                    onChange={(e) => handleChange("businessState", e)}
                  >
                    <GetStatesOfCountry
                      countryCode={
                        formData.businessCountry ? formData.businessCountry : ""
                      }
                    />
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid State.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={2}>
                <Form.Group>
                  <Form.Select
                    aria-label="Default select example"
                    className="filedbg customC-select "
                    value={formData.businessCity ? formData.businessCity : ""}
                    onChange={(e) => handleChange("businessCity", e)}
                  >
                    <GetCitiesOfState
                      countryCode={
                        formData.businessCountry ? formData.businessCountry : ""
                      }
                      stateCode={
                        formData.businessState ? formData.businessState : ""
                      }
                    />
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid City.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xs={12} md={4}>
                <div className="d-flex search-baree">
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="🔍 Search"
                    className="custom-inputee custom-placeholder"
                    onChange={handleInputChange}
                    value={formData.name}
                    autocomplete="off"
                  />
                  <button
                    className="search-button"
                    type="submit"
                    disabled={!formData.name}
                  >
                    <img src={searchIcon} alt="Search" />
                  </button>
                </div>

                {formData.name &&
                suggestions.length > 0 &&
                !selectedSuggestion ? (
                  <ul className="suggestions-list">
                    {suggestions.map((category) => (
                      <li
                        key={category._id}
                        onClick={() => handleSuggestionClick(category)}
                        className="suggestion-item"
                      >
                        <span className="suggestion-icon">🔍</span>
                        <div className="suggestion-details">
                          <span className="suggestion-name">
                            {category.name}
                          </span>
                          <span className="suggestion-category">Category</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  formData.name &&
                  suggestions.length === 0 &&
                  !selectedSuggestion && (
                    <ul className="suggestions-list">
                      <li className="suggestion-item no-match">
                        <span className="suggestion-name">No match found</span>
                      </li>
                    </ul>
                  )
                )}
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
      <section id="category" className="category-section job-cards-container">
        <Container className="job-cards-container">
          <Row className="mb-4">
            <Col md={6}>
              {settingData?.sectionLeft ? (
                <img
                  src={imgPath(settingData.sectionLeft)}
                  alt="Section Left"
                  style={{
                    width: "100%",
                    height: "165px",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
              ) : (
                <div
                  className="placeholder-card"
                  style={{
                    backgroundColor: "#3c507f80",
                    width: "100%",
                    height: "165px",
                    borderRadius: "8px",
                  }}
                ></div>
              )}
            </Col>
            <Col md={6}>
              {settingData?.sectionLeft ? (
                <img
                  src={imgPath(settingData.sectionRight)}
                  alt="Section Left"
                  style={{
                    width: "100%",
                    height: "165px",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
              ) : (
                <div
                  className="placeholder-card"
                  style={{
                    backgroundColor: "#50ADBFCC",
                    width: "100%",
                    height: "165px",
                    borderRadius: "8px",
                  }}
                ></div>
              )}
            </Col>
          </Row>

          <div class="main-container">
            <div className="jobs-section">
              <div className="section-header">
                <h4 style={{ color: "#f8f8f8" }}>Jobs</h4>
                <a className="view-all" onClick={handleViewAllClick}>
                  View All
                </a>
              </div>
              <div className="job-list scroll-enabled">
                {jobData.length > 0 ? (
                  jobData.map((job, idx) => (
                    <div className="job-card" key={idx}>
                      <div className="logo-container">
                        <img
                          src={job.logoPath || joblogo}
                          alt="Company Logo"
                          className="company-logo"
                          width={67}
                          height={61}
                        />
                        <small className="company-name">
                          {truncateText(job.companyName, 30)}
                        </small>
                      </div>
                      <div className="job-details">
                        <h4>{truncateText(job.jobName, 30)}</h4>
                        <p>
                          <i className="fas fa-map-marker-alt"></i>{" "}
                          {truncateText(job.location, 40)}
                        </p>
                        <p>Job Description: {truncateText(job.desc, 50)}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-data">
                    <p style={{ fontSize: "19px" }}>
                      No jobs available at the moment.
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="news-section">
              <div className="Newssection-header">
                <h4 className=" m-0" style={{ color: "#f8f8f8" }}>
                  Trending News
                </h4>
                {/* <a className="view-all" onClick={toggleScroll}>
                                    {isScrollEnabled ? 'Show Less' : 'View All'}
                                </a> */}
              </div>
              <span style={{ color: "#f8f8f8", fontSize: "8px" }}>
                Source@ Times of India
              </span>
              {/* <div className={`job-list ${isScrollEnabled ? 'scroll-enabled' : ''}`}> */}
              <div
                className="job-list scroll-enabled"
                style={{ marginTop: "5px" }}
              >
                {newsData.map((news, index) => {
                  const getNestedProperty = (obj, path, defaultValue) => {
                    return (
                      path.reduce(
                        (acc, key) => (acc && acc[key] ? acc[key] : undefined),
                        obj
                      ) || defaultValue
                    );
                  };

                  const thumbnailUrl = getNestedProperty(
                    news,
                    ["media:content", "media:thumbnail", "$", "url"],
                    "default-placeholder-image.jpg"
                  );

                  const description =
                    news?.description?.replace(/<[^>]*>/g, "") ||
                    "No description available.";

                  return (
                    <a
                      href={news.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="news-link"
                      key={index}
                    >
                      <div className="news-item">
                        <img
                          src={thumbnailUrl}
                          alt={news.title}
                          className="news-image"
                        />
                        <div className="news-content">
                          <h4>{news.title}</h4>
                          <p>{description}</p>
                        </div>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>

            <div class="institutes-section">
              <div class="Institutesection-header">
                <h4 style={{ color: "#f8f8f8", fontSize: "18px" }}>
                  Latest Update
                </h4>
                {/* <a class="view-all">View All</a> */}
              </div>
              <div class="institutes-card">
                <img
                  src={imgPath(settingData?.institutesImg)}
                  alt="Latest Update"
                  className="institutes-img"
                />
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="section-header">
              <h4 style={{ color: "#212D40" }}>Category</h4>
              <a className="view-all" onClick={toggleShowAll}>
                {showAll ? "Show Less" : "View All"}
              </a>
            </div>
            <Row>
              <div className="card-containerMain">
                {displayedCategories &&
                  objToArray(displayedCategories).map((item, index) => (
                    <div
                      className="category-cardMain"
                      key={index}
                      onClick={() => handleCardClick(item)}
                    >
                      <div className="icon-containerMain">
                        {/* <img
                          as="img"
                          variant="top"
                          src={imgPath(item.categoryImg) + "?v=1"} // Add version to prevent cache issues
                          alt={item.name}
                          className="card-image"
                        /> */}
                        <Card.Img
                          variant="top"
                          src={imgPath(item.categoryImg)}
                          alt={item.name}
                          className="card-image"
                        />

                        {/* <Card.Img
                          variant="top"
                          src={imgPath(item.whiteImg)}
                          alt={item.name}
                          className="card-image-hover"
                        /> */}
                      </div>
                      <Card.Body className="card-body-responsiveMain">
                        <Card.Text>{item.name}</Card.Text>
                      </Card.Body>
                    </div>
                  ))}
              </div>
            </Row>
          </div>
        </Container>
      </section>
      <HomeFooter />
      <SubFooter />
    </Fragment>
  );
};

export default Main;
