import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { getPostJobDataAction } from "../../action/Admin.action";
import { confirmAlert } from "react-confirm-alert";
import { _deleteAction } from "../../action/Admin.action";
import { setAlert } from "../../slices/home";
import { useDispatch } from "react-redux";

const List = () => {
  const dispatch = useDispatch();

  const [dataList, setDataList] = useState([]);
  const getDataList = async () => {
    const resp = await getPostJobDataAction();
    if (resp.code === 200) {
      setDataList(resp.data);
    }
  };
  useEffect(() => {
    getDataList();
  }, []);

  const _delete = async (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            dispatch(
              setAlert({
                open: false,
                severity: "success",
                msg: "Loading...",
                type: "loader",
              })
            );

            const resp = await _deleteAction({
              _id: id,
              type: "postJobs",
            });
            if (resp.code === 200) {
              getDataList();
              dispatch(
                setAlert({
                  open: true,
                  severity: "success",
                  msg: "You have successfully deleted job.",
                  type: "",
                })
              );
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  return (
    <div className="mt-2">
      <Card className="mt-5">
        <Card.Body>
          <Row className="justify-content-center mt-3 pt-2">
            <Col md={12} lg={12}>
              <h6 className="crdHd1">Job Management</h6>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col lg={12}>
              <table className="table table-light">
                <thead className="headingtbl">
                  <tr>
                    <th scope="col">S. No.</th>
                    <th scope="col">Job Name</th>
                    <th scope="col">Company Name</th>
                    <th scope="col">Location</th>
                    <th scope="col">Action </th>
                  </tr>
                </thead>
                <tbody>
                  {dataList.length > 0 ? (
                    dataList.map((job, index) => (
                      <tr key={job._id}>
                        <td>{index + 1}</td>
                        <td>{job.jobName}</td>
                        <td>{job.companyName}</td>
                        <td>{job.location || "N/A"}</td>
                        <td>
                          <span
                            title="Delete Job"
                            className="px-1 hand text-danger"
                            onClick={() => _delete(job._id)}
                          >
                            <FontAwesomeIcon icon={faTrash} size="xs" />
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No jobs available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default List;
