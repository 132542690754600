import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Modal } from "react-bootstrap";
import {
  getAdminUserListDataAction,
  adminSendNotificationDataAction,
} from "../../action/Admin.action";
import Pagination from "../../admin/common/Pagination";
import { useDispatch } from "react-redux";
import { setAlert } from "../../slices/home";
import { ToastContainer, toast } from "react-toastify";
import { cls } from "react-image-crop";

const List = () => {
  const dispatch = useDispatch();
  const [isDisabled, setDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [searchUserType, setSearchUserType] = useState("");
  const [formData, setFormData] = useState({
    page: 0,
    limit: 10,
    sortName: "createdAt",
    sortBy: "DESC",
  });

  const [validated, setValidated] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [sendAll, setSendAll] = useState(false);

  const getDataList = async (action = "") => {
    let data = { ...formData };

    if (action === "clear") {
      data = { page: 0, limit: 10, sortName: "createdAt", sortBy: "DESC" };
      setSearchName("");
      setSearchUserType("");
      setFormData(data);
    } else {
      if (searchName.trim() !== "") {
        data.fullName = searchName;
      }
      if (searchUserType) {
        data.userType = Number(searchUserType);
      }
    }

    const { totalItems, ...filteredData } = data;

    dispatch(
      setAlert({
        open: true,
        severity: "success",
        msg: "Loading...",
        type: "loader",
      })
    );

    const resp = await getAdminUserListDataAction(filteredData);

    dispatch(
      setAlert({
        open: false,
        severity: "success",
        msg: "Loading...",
        type: "loader",
      })
    );

    if (resp.code === 200) {
      setDataList(resp.data);
      setFormData((prev) => ({ ...prev, totalItems: resp.count || 0 }));
    }
  };

  useEffect(() => {
    getDataList();
  }, [formData.page]);

  const handleCheckboxChange = (userId) => {
    setSelectedUsers((prevSelected) => {
      if (prevSelected.includes(userId)) {
        return prevSelected.filter((id) => id !== userId);
      } else {
        return [...prevSelected, userId];
      }
    });
  };

  const handleSendAllClick = () => {
    selectedUsers.length > 0 ? setSendAll(false) : setSendAll(true);
    setShowModal(true);
  };
  console.log("check", sendAll);

  const handleClose = () => {
    setValidated(false);
    setShowModal(false);
    setDescription("");
    setTitle("");
    setSelectedUsers([]);
    setSendAll(false);
  };

  const handleChange = (e) => {
    setDescription(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      let payload = {};

      if (sendAll) {
        payload = { type: "all", title, msg: description };
      } else if (selectedUsers.length > 0) {
        payload = { userIdArr: selectedUsers, title, msg: description };
      } else {
        toast.error("Please select at least one user.");
        setDisabled(false);
        return;
      }

      const resp = await adminSendNotificationDataAction(payload);

      if (resp.code === 200) {
        toast.success("Message sent successfully!");
        setTimeout(() => {
          handleClose();
        }, 2000);
      } else {
        setDisabled(false);
        toast.error(resp.msg);
      }
    }
  };

  const startSno = (formData.page || 0) * (formData.limit || 10) + 1;

  return (
    <div className="mt-2">
      <Card className="mt-5">
        <Card.Body className="p-2">
          <Row className="justify-content-center mt-3 pt-2">
            <Col md={10} lg={10}>
              <h6 className="crdHd1">User Notification Management</h6>
            </Col>
            <Col md={2} lg={2}>
              <button
                className="btn btn-primary vndrBtn ms-4"
                type="button"
                onClick={handleSendAllClick}
              >
                Send All
              </button>
            </Col>
            <Row>
              <Col className="pd-5">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Name
                </label>
                <input
                  type="text"
                  className="form-control filedbg cmnsz"
                  id="exampleFormControlInput1"
                  placeholder="Enter Name"
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                />
              </Col>
              <Col className="pd-5">
                <label htmlFor="userTypeSelect" className="form-label">
                  User Type
                </label>
                <select
                  className="form-control filedbg no_appear"
                  id="userTypeSelect"
                  value={searchUserType}
                  onChange={(e) => setSearchUserType(e.target.value)}
                >
                  <option value="">Select Type</option>
                  <option value="3">Vendor</option>
                  <option value="4">Crew</option>
                  <option value="5">Other</option>
                </select>
              </Col>
              <Col className="pd-5 pt-4">
                <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-1">
                  <button
                    className="btn btn-primary vndrBtn"
                    type="button"
                    onClick={() => getDataList()}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-primary vndrBtn"
                    type="button"
                    onClick={() => getDataList("clear")}
                  >
                    Clear
                  </button>
                </div>
              </Col>
            </Row>
          </Row>
          <Row className="mt-2">
            <Col lg={12}>
              <table className="table table-light">
                <thead className="headingtbl">
                  <tr>
                    <th></th>
                    <th>S. No.</th>
                    <th>Name</th>
                    <th>Company Name</th>
                    <th>Phone</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {dataList.map((user, index) => (
                    <tr key={user.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedUsers.includes(user._id)}
                          onChange={() => handleCheckboxChange(user._id)}
                        />
                      </td>

                      <td>{startSno + index}</td>
                      <td>{user.fullName}</td>
                      <td>{user.companyName}</td>
                      <td>{user.phone}</td>
                      <td>
                        {["Company", "Vendor", "Crew", "Other"][
                          user.userType - 2
                        ] || "Unknown"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                itemsPerPage={formData.limit}
                totalItems={formData.totalItems || 0}
                setPage={(e) => setFormData({ ...formData, ...e })}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton className="modal_header">
          <Modal.Title>Send Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={title}
                onChange={handleTitleChange}
                pattern="^[a-zA-Z][a-zA-Z0-9\s@#$%&*.,\-]{1,127}$"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid title
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="notificationDescription">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                maxLength={150}
                rows={3}
                value={description}
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid description.
              </Form.Control.Feedback>
            </Form.Group>
            <button type="submit" className="btn vndrBtn text-white mt-3">
              Send
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default List;
