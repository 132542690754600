import React, { Fragment, useEffect } from "react";
import HomeHeader from "../Header/HomeHeader";
import HomeFooter from "../Footer/HomeFooter";
import { Card } from "react-bootstrap";
import SubFooter from "../Footer/SubFooter";
import HeaderloginLite from "../Header/HeaderloginLite";

const Institutions = ({ selected }) => {
  const status = JSON.parse(localStorage.getItem("status"));
  const institutionsData = {
    // "Andhra Pradesh": [
    //   "1. Annapurna International School of Film and Media",
    //   "2. Ramoji Academy of Film and Television",
    //   "3. International Academy of Film and Media",
    //   "4. Potti Sreeramulu Telugu University",
    //   "5. Dr. B.R. Ambedkar University",
    // ],

    "Andhra Pradesh": [
      {
        "name": "Annapurna International School of Film and Media",
        "address": "Inside Annapurna Studios, Road No. 2, Banjara Hills, Hyderabad - 500 034",
        "email": ["info@acfm.edu.in", "admissions@acfm.edu.in"],
        "website": "http://www.acfm.edu.in"
      },
      {
        "name": "Ramoji Academy of Film and Television",
        "address": "Ramoji Academy of Movies, Ramoji Film City, Hyderabad - 501512, Telangana.",
        "email": ["info@ramojiacademy.com", "techsupport@ramojiacademy.com"],
        "website": "www.ramojiacademy.com"
      },
      {
        "name": "Potti Sreeramulu Telugu University",
        "address": "Lalitha Kala Kshetram, Near, Public Garden Rd, Public Gardens, Red Hills, Nampally, Hyderabad, Telangana 500004",
        "website": "http://www.pstucet.org/"
      },
      {
        "name": "Dr. B.R. Ambedkar University",
        "address": "Ambedkar University Rd, Etcherla, Andhra Pradesh 532410",
        "website": "https://www.brau.edu.in/"
      }
    ],

    "Assam": [
      {
        "name": "Dr. Bhupen Hazarika Regional Government Film and Television Institute",
        "address": "Nizsundarighopa, Assam 781030",
        "website": "https://dbhrgfti.assam.gov.in"
      },
      {
        "name": "Assam University (Department of Mass Communication)",
        "address": "Assam University, Silchar – 788 011, Assam, India",
        "website": "http://www.aus.ac.in"
      },
      {
        "name": "Gauhati University (Department of Communication and Journalism)",
        "address": "Gopinath Bordoloi Nagar, Jalukbari, Guwahati, Assam 781014",
        "website": "https://gauhati.ac.in"
      }
    ],
    "Delhi": [
      {
        "name": "A.J.K. Mass Communication Research Centre, Jamia Millia Islamia",
        "address": "Jamia Millia Islamia, Jamia Nagar, Okhla, New Delhi, Delhi 110025",
        "website": "https://www.jmi.ac.in/aboutjamia/centres/mcrc/introduction"
      },
      {
        "name": "Indian Institute of Mass Communication",
        "address": "JNU New Campus, Aruna Asaf Ali Marg, New Delhi, Delhi 110067",
        "website": "http://www.iimc.gov.in",
        "email": ["ar.admin@iimc.gov.in"]
      },
      {
        "name": "Hansraj College (Add-on courses in Mass Communication and Filmmaking)",
        "address": "Mahatma Hansraj Marg, Malka Ganj, Delhi 110007, India",
        "website": "https://www.hansrajcollege.ac.in"
      },
      {
        "name": "Delhi College of Arts and Commerce (Department of Journalism)",
        "address": "New Moti Bagh, Netaji Nagar, New Delhi, Delhi 110023",
        "website": "https://www.dcac.du.ac.in"
      },
      {
        "name": "Lady Shri Ram College for Women (Department of Journalism)",
        "address": "Lajpat Nagar-IV, New Delhi – 110024",
        "website": "https://www.lsr.edu.in"
      }
    ],
    "Gujarat": [
      {
        "name": "National Institute of Design",
        "address": "Opposite Tagore Hall, Rajnagar Society, Paldi, Ahmedabad, Gujarat 380007",
        "website": "https://www.nid.edu"
      },
      {
        "name": "Maharaja Sayajirao University of Baroda (Faculty of Journalism and Communication)",
        "address": "Next to Computer Centre D. N. Hall Campus The Maharaja Sayajirao University of Baroda, Pratapgunj, Vadodara, Gujarat 390020",
        "website": "https://www.msubaroda.ac.in/academics/FJC"
      },
      {
        "name": "Gujarat University (Department of Journalism and Mass Communication)",
        "address": "Navarangpura Ahmedabad-380009, Gujarat, INDIA",
        "website": "https://www.gujaratuniversity.ac.in"
      },
      {
        "name": "Saurashtra University (Department of Journalism)",
        "address": "Saurashtra University, Rajkot, Gujarat, India",
        "website": "https://www.saurashtrauniversity.edu"
      }
    ],
    "Karnataka": [
      {
        "name": "Government Film and Television Institute",
        "address": "Hesaraghatta Post, Bengaluru, Karnataka 560088",
        "website": "https://www.gpt.karnataka.gov.in/gfti"
      },
      {
        "name": "Srishti Institute of Art, Design, and Technology",
        "address": "MAHE Bengaluru Campus, Govindapura Yelahanka, Bengaluru 560064",
        "website": "https://www.srishtimanipalinstitute.in"
      },
      {
        "name": "Karnataka State Open University (Department of Journalism and Mass Communication)",
        "address": "Karnataka State Open University, Mukthagangothri, Mysuru, Karnataka - 570006",
        "website": "https://www.ksoumysuru.ac.in"
      },
      {
        "name": "Christ University (Department of Media Studies)",
        "address": "Bangalore, Karnataka",
        "website": "https://m.christuniversity.in"
      },
      {
        "name": "Manipal Academy of Higher Education (School of Communication)",
        "address": "Madhav Nagar, Eshwar Nagar, Manipal, Karnataka 576104",
        "website": "https://www.manipal.edu"
      }
    ],
    "Kerala": [
      {
        "name": "K.R. Narayanan National Institute of Visual Science and Arts",
        "address": "Film Institute, Kanjiramattom - Kavungumpaalam Road, Thekkumthala, Kanjiramattom, Chengalam East, Kerala 686585",
        "website": "https://www.krnnivsa.com"
      },
      {
        "name": "Amrita School of Arts and Sciences (Department of Visual Media and Communication)",
        "address": "Amrita Vishwa Vidyapeetham, Amrita School of Arts & Sciences, Brahmasthanam, Edappally North P.O., Kochi - 682024 Kerala, India",
        "website": "https://www.amrita.edu"
      },
      {
        "name": "Kerala University (Department of Communication and Journalism)",
        "address": "Karyavattom, Thiruvananthapuram, Kerala 695581",
        "website": "https://www.keralauniversity.ac.in"
      },
      {
        "name": "Calicut University (Department of Journalism and Mass Communication)",
        "address": "Department of Journalism and Mass Communication University of, Kozhikode, Thenhipalam, Kerala 673636",
        "website": "https://www.journalism.uoc.ac.in"
      },
      {
        "name": "St. Joseph College of Communication",
        "address": "Media Village Kurishummoodu P.O, Changanassery, Kerala 686104",
        "website": "https://www.sjcc.ac.in"
      }
    ],
    "Madhya Pradesh": [
      {
        "name": "Makhanlal Chaturvedi National University of Journalism and Communication",
        "address": "Makhanpuram, Opposite M.P. State Shooting Academy, Bishankhedi, Bhopal, Madhya Pradesh – 462044",
        "website": "https://www.mcu.ac.in"
      },
      {
        "name": "Jagran Lakecity University (School of Journalism and Communication)",
        "address": "Jagran Lakecity University Student Enrichment Hub, Near Kaliasoth Barrage, Chandanpura, Bhopal – 462007",
        "website": "https://www.jlu.edu.in",
        "email": ["info@jlu.edu.in"]
      },
      {
        "name": "School of Journalism & Mass Communication, DAVV",
        "address": "Davv Takshila Parisar, Indore, Madhya Pradesh 452001",
        "website": "https://www.dauniv.ac.in"
      },
      {
        "name": "EMRC DAVV",
        "address": "Davv Takshila Parisar, Indore, Madhya Pradesh 452001",
        "website": "https://emrcdavv.edu.in"
      },
      {
        "name": "Rabindranath Tagore University (Faculty of Journalism and Mass Communication)",
        "address": "Rabindranath Tagore University, Mendua, Post: Bhojpur, Near - Bangrasiya Chouraha, Bhopal-Chiklod Road, District: Raisen, (M.P.)",
        "website": "https://www.rntu.ac.in"
      },
      {
        "name": "Madhya Pradesh School of Drama",
        "address": "Rajbhavan Rd, near Sardar Vallabh Bhai Patel Polytechnic Chouraha, Professors Colony, Bhopal, Madhya Pradesh 462002",
        "website": "https://www.mpsd.in"
      }
    ],
    "Maharashtra": [
      {
        "name": "Film and Television Institute of India",
        "address": "11, Law College Rd, Shanti Sheela Society, Deccan Gymkhana, Pune, Maharashtra 411004",
        "website": "www.ftii.ac.in"
      },
      {
        "name": "Whistling Woods International",
        "address": "Aarey Milk Colony, Film City Complex, Aarey Colony, Goregaon, Mumbai, Maharashtra 400065",
        "website": "www.whistlingwoods.net"
      },
      {
        "name": "Xavier Institute of Communications",
        "address": "ST. XAVIERS COLLEGE, 5, Mahapalika Marg, Dhobi Talao, Chhatrapati Shivaji Terminus Area, Fort, Mumbai, Maharashtra 400001",
        "website": "www.xaviercomm.org"
      },
      {
        "name": "Symbiosis Institute of Media and Communication",
        "address": "Gram: Lavale, Tal: Mulshi, Dist: Pune, Maharashtra, India Pin: 412115",
        "website": "www.simc.edu"
      },
      {
        "name": "Mumbai University (Department of Communication and Journalism)",
        "address": "Kalina, Santacruz East, Mumbai, Maharashtra 400029",
        "website": "www.dcjmumbai.mu.ac.in"
      }
    ],
    "Odisha": [
      {
        "name": "Biju Pattnaik Film and Television Institute of Odisha",
        "address": "BOSE Campus, Professors Colony, Cuttack, Odisha 753007",
        "website": "www.bpftio.ac.in"
      },
      {
        "name": "Indian Institute of Mass Communication, Dhenkanal",
        "address": "Sanchar Marg, Dhenkanal, Odisha 759001",
        "website": "www.iimc.gov.in"
      },
      {
        "name": "Utkal University (Department of Journalism and Mass Communication)",
        "address": "Utkal University, Vani Vihar, Bhubaneswar, Odisha 751004",
        "website": "www.utkaluniversity.ac.in"
      },
      {
        "name": "Berhampur University (Department of Journalism and Mass Communication)",
        "address": "Berhampur University Bhanja Bihar, Berhampur Dist: Ganjam, Odisha 760007",
        "website": "www.buodisha.edu.in"
      },
      {
        "name": "Ravenshaw University (Department of Journalism and Mass Communication)",
        "address": "Odisha",
        "website": "www.ravenshawuniversity.ac.in"
      }
    ],
    "Tamil Nadu": [
      {
        "name": "M.G.R. Government Film and Television Training Institute",
        "address": "4th Cross St, adjacent to Mathematical Science Institute, CIT Campus, Taramani, Chennai, Tamil Nadu 600113",
        "website": "https://www.tn.gov.in"
      },
      {
        "name": "L.V. Prasad Film and TV Academy",
        "address": "28, Arunachalam Road, Saligramam, Chennai – 600 093, Tamil Nadu, India",
        "website": "https://vpcollge.edu.in"
      },
      {
        "name": "Asian College of Journalism",
        "address": "2nd Main Rd, Taramani, CIT Campus, Taramani, Chennai, Tamil Nadu 600113",
        "website": "https://www.asianmedia.org.in"
      },
      {
        "name": "BOFTA – Blue Ocean Film and Television Academy",
        "address": "Plot No.262-A, Indira Gandhi Road, Ramakrishna Nagar, Off., Kamarajar Salai, Alwartirunagar, Valasaravakkam, Chennai, Tamil Nadu 600087",
        "website": "https://www.bofta.in"
      },
      {
        "name": "SRM Institute of Science and Technology (School of Media Studies)",
        "address": "SRM Nagar, Kattankulathur - 603 203 Chengalpattu District, Tamil Nadu.",
        "website": "www.srmist.edu.in"
      }
    ],
    "Uttar Pradesh": [
      {
        "name": "Sam Higginbottom University of Agriculture, Technology and Sciences (School of Film and Mass Communication)",
        "address": "Rewa Road Old Bridge, near to Yamuna, Naini, Prayagraj, Uttar Pradesh 211007",
        "website": "https://shuats.edu.in"
      },
      {
        "name": "Amity School of Communication",
        "address": "Sector 125, Noida, Uttar Pradesh 201313",
        "website": "https://www.amity.edu/asco/"
      },
      {
        "name": "Banaras Hindu University (Department of Journalism and Mass Communication)",
        "address": "Banaras Hindu University, Kabir Colony, Varanasi, Uttar Pradesh 221005",
        "website": "www.bhu.ac.in"
      },
      {
        "name": "Aligarh Muslim University (Department of Mass Communication)",
        "address": "University Rd, AMU Campus, Aligarh, Uttar Pradesh 202002",
        "website": "www.amu.ac.in"
      }
    ],
    "West Bengal": [
      {
        "name": "Satyajit Ray Film and Television Institute",
        "address": "E.M. BYEPASS ROAD, P.O, Eastern Metropolitan Bypass, Panchasayar, Kolkata, West Bengal 700094",
        "website": "https://srfti.ac.in"
      },
      {
        "name": "Jadavpur University (Department of Film Studies)",
        "address": "UG ARTS AND U. G SCIENCE BUILDING, 188, Raja Subodh Chandra Mallick Rd, Jadavpur University Campus Area, Poddar Nagar, Jadavpur, Kolkata, West Bengal 700032",
        "website": "https://jaduniv.edu.in/academics/film-studies/"
      },
      {
        "name": "University of Calcutta (Department of Journalism and Mass Communication)",
        "address": "Department of Journalism and Mass Communication, University of Calcutta, 87/1, College Street, Kolkata-700 073",
        "website": "www.caluni.ac.in"
      },
      {
        "name": "NSHM Knowledge Campus (School of Media and Communication)",
        "address": "24, Basanta Lal Saha Rd, Ajoy Nagar, Buroshibtalla, Tollygunge, Kolkata, West Bengal 700053",
        "website": "www.nshm.com"
      },
      {
        "name": "St. Xavier’s College (Department of Mass Communication and Videography)",
        "address": "St. Xavier’s College (Autonomous), Kolkata 30, Mother Teresa Sarani, Kolkata - 700016, West Bengal, India",
        "website": "https://sxccal.edu/department-of-mass-communication-videography"
      }
    ],
    "Rajasthan": [
      {
        "name": "University of Rajasthan, Jaipur",
        "address": "Jawahar Lal Nehru Marg, Rajasthan University Campus, Talvandi, Jaipur, Rajasthan 302004",
        "website": "www.uniraj.ac.in"
      },
      {
        "name": "Amity University Rajasthan, Jaipur",
        "address": "SP-1 Kant Kalwar, NH11C, RIICO Industrial Area, Rajasthan 303002",
        "website": "https://www.amity.edu/jaipur"
      },
      {
        "name": "Manipal University Jaipur",
        "address": "Jaipur-Ajmer Express Highway, Dehmi Kalan, Near GVK Toll Plaza, Jaipur, Rajasthan 303007",
        "website": "https://jaipur.manipal.edu"
      },
      {
        "name": "Banasthali Vidyapith, Tonk",
        "address": "Vanasthali Rd, Aliyabad, Radha Kishnpura, Rajasthan 304022",
        "website": "http://www.banasthali.org"
      }
    ],
    "Punjab": [
      {
        "name": "Panjab University, Chandigarh",
        "address": "Sector 14, Chandigarh, 160014",
        "website": "www.puchd.ac.in"
      },
      {
        "name": "Lovely Professional University (LPU), Phagwara",
        "address": "Punjab",
        "website": ""
      },
      {
        "name": "Guru Nanak Dev University, Amritsar",
        "address": "Chheharta Rd, Makka Singh Colony, Amritsar, Punjab 143005",
        "website": "www.gndu.ac.in"
      }
    ],
    "Chhattisgarh": [
      {
        "name": "AAFT University, Raipur",
        "address": "Math, Kharora, Raipur, Chhattisgarh 493225",
        "website": "www.aaft.edu"
      },
      {
        "name": "Kushabhau Thakre Patrakarita Avam Jansanchar University, Raipur",
        "address": "Kathadih, Sunder Nagar, Raipur, Chhattisgarh 492013",
        "website": "www.ktujm.ac.in"
      },
      {
        "name": "MATS University, Raipur",
        "address": "MATS Tower, Pagaria Complex, Pandri, Raipur, Chhattisgarh 492004",
        "website": "https://matsuniversity.ac.in"
      },
      {
        "name": "Pt. Ravishankar Shukla University, Raipur",
        "address": "Great Eastern Rd, Amanaka, Raipur, Chhattisgarh 492010",
        "website": "https://www.prsu.ac.in"
      },
      {
        "name": "Disha Institute of Management and Technology, Raipur",
        "address": "Vidhansabha-Chandrakhuri Marg, near Gyanganga School, Satya Vihar, Chandkhuri, Pacheda-1, Chhattisgarh 492101",
        "website": "https://www.dimatindia.in"
      }
    ],
    "Bihar": [
      {
        "name": "Patna University, Patna",
        "address": "Ashok Rajpath Rd, Patna University Campus, Patna, Bihar 800005",
        "website": "www.pup.ac.in"
      },
      {
        "name": "Nalanda Open University, Patna",
        "address": "Biscomaun Bhawan, 2nd, 3rd Floor, Gandhi Maidan Rd, Patna, Bihar 800001",
        "website": "www.nou.ac.in"
      },
      {
        "name": "Magadh University, Bodh Gaya",
        "address": "Bodh Gaya, Bihar 824234",
        "website": "https://www.magadhonline.in"
      },
      {
        "name": "Aryabhatta Knowledge University, Patna",
        "address": "Mithapur Farm Area, Mithapur, Patna, Bihar 800001",
        "website": "www.akubihar.ac.in"
      },
      {
        "name": "Tilka Manjhi Bhagalpur University, Bhagalpur",
        "address": "Ravindra Bhavan Rd, Sitanabad, Tatarpur, Bhagalpur, Bihar 812007",
        "website": "https://www.tmbuniv.ac.in"
      }
    ],
    "Jharkhand": [
      {
        "name": "Ranchi University, Ranchi",
        "address": "Ranchi University, Shahid Chowk, Ranchi - 834001",
        "website": "https://ranchiuniversity.ac.in"
      },
      {
        "name": "Vinoba Bhave University, Hazaribagh",
        "address": "Vinoba Bhave University Rd, Sindoor, Hazaribagh, Jharkhand 825301",
        "website": "www.vbu.ac.in"
      },
      {
        "name": "Sido Kanhu Murmu University, Dumka",
        "address": "Dumka Sahebganj Rd, Dighi, Dumka, Jharkhand 814101",
        "website": "www.skmu.ac.in"
      },
      {
        "name": "Kolhan University, Chaibasa",
        "address": "Chaibasa, Jharkhand 833202",
        "website": "www.kuuniv.in"
      }
    ],
    "Jammu and Kashmir": [
      {
        "name": "Crown City Academy of Film & Television (CCAFT), Jammu",
        "address": "Ranjit Global School, Seora Road, Kunjwani, Jammu, Jammu and Kashmir 180012",
        "website": ""
      }
    ],
    "Himachal Pradesh": [
      {
        "name": "Himalayan Film School, Bir",
        "address": "Bir, Himachal Pradesh",
        "website": ""
      },
      {
        "name": "Soul Frame Film and Art School, Himachal Pradesh",
        "address": "Dixit Niwas Shyamnagar, near Bachpan School, Dharamshala, Himachal Pradesh, India 176215",
        "website": "https://soulframefilmartschool.com/"
      },
      {
        "name": "MS Asian Film Academy, Himachal Pradesh",
        "address": "Chandimandir Cantonment, Chandigarh, Zirakpur, Chandigarh 160102",
        "website": "https://msasianfilmacademy.com"
      }
    ]
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      {status === 1 ? <HeaderloginLite /> : <HomeHeader />}
      <section className="container mb-4" style={{ marginTop: "125px" }}>
        <div className="text-center mb-4 ">
          <h2 className="allTextColor">Institutions</h2>
        </div>
        <div className="table-responsive">
          {/* <table className="custom-responsive-table">
            <thead>
              <tr>
                <th className="text-center">State Name </th>
                <th className="text-center">Institutions</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(institutionsData).map(
                ([state, institutions], index) => (
                  <tr key={index}>
                    <td className="fw-bold align-middle text-center">
                      {state}
                    </td>
                    <td className="text-start">
                      <ul className="px-5">
                        {institutions.map((inst, i) => (
                          <li key={i}>{inst}</li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table> */}

          <table className="custom-responsive-table">
            <thead>
              <tr>
                <th className="text-center">State Name</th>
                <th className="text-center">Institution</th>
                <th className="text-center">Contact Details</th>
                <th className="text-center">. </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(institutionsData).map(([state, institutions], index) => (
                <Fragment key={index}>
                  <tr>
                    <td className="fw-bold align-middle text-center" rowSpan={institutions.length + 1}>{state}</td>
                  </tr>
                  {institutions.map((inst, i) => (
                    <tr key={i}>
                      <td>{inst.name}</td>
                      <td>{inst.address}</td>
                      <td>{inst.email && inst.email.length > 0 && (
                        <>
                          Email:{" "}
                          {inst.email.map((email, idx) => (
                            <a key={idx} href={`mailto:${email}`} className="mx-1">
                              {email}
                            </a>
                          ))}
                          <br />
                        </>
                      )}
                        {inst.website && (
                          <>
                            Website:{" "}
                            <a href={inst.website} target="_blank" rel="noopener noreferrer">
                              {inst.website}
                            </a>
                          </>
                        )}</td>
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </section>
      <HomeFooter />
      <SubFooter />
    </Fragment>
  );
};

export default Institutions;
