import React, { Fragment, useState, useEffect } from "react";
import { Col, Container, Row, Table, Spinner } from "reactstrap";
import { Modal, Button, Form } from 'react-bootstrap';
import { ToastContainer } from "react-toastify";
import Header from "../Header/HeaderloginLite";
import "../../../assets/css/style.css";
import { useDispatch } from "react-redux";
import { getPostJobsAction, _deleteAction, getEnquiryAction, changeStatusAction } from "../../../action/Admin.action";
import { Link, useNavigate } from "react-router-dom";
import { setAlert } from "../../../slices/home";
import { confirmAlert } from "react-confirm-alert";
import PostApplyDetailsModel from "./PostApplyDetailsModel";
import SenderEnquiryDetailModel from "./SenderEnquiryDetailModel";
import HomeFooter from "../Footer/HomeFooter";
import SubFooter from "../Footer/SubFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import {
    faBan,
    faPencil,
    faTrash,
    faCheck,
} from "@fortawesome/free-solid-svg-icons";

const UserDashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [jobData, setJobData] = useState([]);
    const [enquiryData, setEnquiryList] = useState([]);
    const [jobModalOpen, setJobModalOpen] = useState(false);
    const [enquiryModalOpen, setEnquiryModalOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [selectedEnquiry, setSelectedEnquiry] = useState(null);
    const [loading, setLoading] = useState(false);
    const openJobModal = (job) => {
        setSelectedJob(job);
        setJobModalOpen(true);
    };

    const closeJobModal = () => {
        setSelectedJob(null);
        setJobModalOpen(false);
    };

    const openEnquiryModal = (item) => {
        setSelectedEnquiry(item);
        setEnquiryModalOpen(true);
    };

    const closeEnquiryModal = () => {
        setSelectedEnquiry(null);
        setEnquiryModalOpen(false);
    };
    const getData = async () => {
        setLoading(true); // Start loading
        try {
            let resp = await getPostJobsAction();
            if (resp.code === 200) {
                setJobData(resp.data);
            }

            let resp2 = await getEnquiryAction();
            if (resp2.code === 200) {
                setEnquiryList(resp2.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false); // Stop loading
        }
    };
    const _deleteEnquiry = async (id) => {
        Swal.fire({
            title: "Confirm to Delete",
            text: "Are you sure you want to delete this?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            customClass: {
                confirmButton: "swal-confirm-btn",
                cancelButton: 'swal-cancel-btn',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                dispatch(
                    setAlert({
                        open: false,
                        severity: "success",
                        msg: "Loading...",
                        type: "loader",
                    })
                );

                const resp = await _deleteAction({ _id: id, type: "enquiry" });
                if (resp.code === 200) {
                    dispatch(
                        setAlert({
                            open: true,
                            severity: "success",
                            msg: "You have successfully deleted.",
                            type: "",
                        })
                    );
                    getData();
                }
            }
        });
    };

    const changeStatus = async (id, status, text) => {
        Swal.fire({
            title: "Confirm to " + text,
            text: "Are you sure to do this?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            customClass: {
                confirmButton: "swal-confirm-btn",
                cancelButton: 'swal-cancel-btn',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                const resp = await changeStatusAction({
                    _id: id,
                    status: status,
                    type: "postJobs",
                });
                if (resp.code === 200) {
                    Swal.fire({
                        title: text + "!",
                        text: "Your job has been " + text,
                        icon: "success",
                        confirmButtonText: "OK",
                        customClass: {
                            confirmButton: "swal-ok-btn"
                        }
                    });
                    getData();
                }
            }
        });
    };

    const _delete = async (id) => {
        Swal.fire({
            title: "Confirm to Delete",
            text: "Are you sure you want to delete this?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            customClass: {
                confirmButton: "swal-confirm-btn",
                cancelButton: 'swal-cancel-btn',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                dispatch(
                    setAlert({
                        open: false,
                        severity: "success",
                        msg: "Loading...",
                        type: "loader",
                    })
                );

                const resp = await _deleteAction({ _id: id, type: "postJobs" });
                if (resp.code === 200) {
                    dispatch(
                        setAlert({
                            open: true,
                            severity: "success",
                            msg: "You have successfully deleted.",
                            type: "",
                        })
                    );
                    getData();
                }
            }
        });
    };

    useEffect(() => {
        getData();
        window.scrollTo(0, 0)
    }, [])

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-GB', options); // 'en-GB' for dd/mm/yyyy format
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };
    return (
        <Fragment>
            <Container fluid className="p-0">
                {/* <Header /> */}

                <Container className="containerHeight" >
                    {/* <div className="dashboard-header mt-4">
                        <h1>Google Ads</h1>
                    </div> */}
                    <div className="dashboard-stats mt-4">
                        <Row className="stat-box-container text-center">
                            {/* {stats.map((stat, idx) => (
                                <Col key={idx} sm={6} md={4} lg={2} className="stat-box">
                                    <div className="stat-value">{stat.value}</div>
                                    <div className="stat-title">{stat.label}</div>
                                </Col>
                            ))} */}
                        </Row>
                    </div>
                    <div className="dashboard-container">
                        <div className="grid-container">
                            {/* My Bookings */}
                            <div className="grid-item bookings pb-5">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="allTextColor" style={{ marginBottom: '18px', marginTop: '20px', }}>Jobs</h5>
                                    <Link className="btn btnColor" to={`${process.env.PUBLIC_URL}/user/addJob`} style={{ height: '33px' }}>Add Job</Link>
                                </div>
                                {loading ? (
                                    <div className="text-center">
                                        <Spinner style={{ color: "#ff5733" }} />
                                    </div>
                                ) : (
                                    <div className="table-container">
                                        <Table className="table-dar">
                                            <thead>
                                                <tr >
                                                    <th scope="col">Job Title</th>
                                                    <th scope="col" style={{ width: "120px" }}>Company Name</th>
                                                    <th scope="col">Location</th>
                                                    <th scope="col" style={{ paddingLeft: "23px" }}>Job Type</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col" style={{ width: 160 }}>Action</th>
                                                </tr>
                                            </thead>
                                        </Table>
                                        <div className="job-table-scroll">
                                            <Table className="table-dar">
                                                <tbody>
                                                    {jobData.length > 0 ? jobData.map((job, idx) => (
                                                        <tr key={job._id}>
                                                            <td style={{ paddingTop: '7px' }}><span title={job.jobName}>{truncateText(`${job.jobName}`, 10)}</span></td>
                                                            <td style={{ paddingTop: '7px' }}><span title={job.companyName}>{truncateText(`${job.companyName}`, 10)}</span></td>
                                                            <td style={{ paddingTop: '7px' }}><span title={job.location}>{truncateText(`${job.location}`, 10)}</span></td>
                                                            <td style={{ paddingTop: '7px', width: "4%" }}><span title={job.jobType}>{job.jobType}</span></td>
                                                            <td style={{ paddingTop: '7px' }}><span title={job.desc}>{truncateText(`${job.desc}`, 10)}</span></td>
                                                            <td style={{ paddingTop: '7px', width: "3%" }}>
                                                                <span className={job.status === 1 ? "status-gree" : "status-red"}>
                                                                    {job.status === 1 ? "Active" : "Inactive"}
                                                                </span>
                                                            </td>
                                                            <td className="table-actions" style={{ width: "8%" }}>
                                                                <button className=" createbtnColor" size="sm" style={{ margin: '5px', marginRight: '0px' }} title="View Job Details" onClick={() => openJobModal(job._id)}><i class="fa-solid fa-eye fa-sm"></i></button>
                                                                <button className="btnColor" size="sm" title="Edit Job" onClick={(e) => navigate(`${process.env.PUBLIC_URL}/user/editJob`, { state: job })} >✎</button>
                                                                {parseInt(job.status) === 1 ? (
                                                                    <>
                                                                        <button
                                                                            size="sm"
                                                                            title="InActive"
                                                                            className="active-inActive-btn"
                                                                            onClick={(e) =>
                                                                                changeStatus(job._id, 0, "InActive")
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon icon={faBan} size="xs" />
                                                                        </button>

                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <button
                                                                            className="active-inActive-btn"
                                                                            size="sm"
                                                                            title="Active"
                                                                            onClick={(e) =>
                                                                                changeStatus(job._id, 1, "Active")
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon icon={faCheck} size="xs" />
                                                                        </button>

                                                                    </>
                                                                )}
                                                                <button className="danger-btn" title="Delete Job" size="sm" onClick={(e) => _delete(job._id)}  >✕</button>

                                                            </td>
                                                        </tr>
                                                    )) : (
                                                        <tr>
                                                            <td colSpan="8" style={{ textAlign: 'center', color: 'white' }}>
                                                                No Jobs Found
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                )}
                                <PostApplyDetailsModel isOpen={jobModalOpen} toggle={closeJobModal} job={selectedJob} />
                            </div>
                            <div className="grid-item enquiry pb-5">
                                <h5 className="allTextColor" style={{ marginBottom: "18px", marginTop: "20px" }}>
                                    Enquiry
                                </h5>

                                {loading ? (
                                    <div className="text-center">
                                        <Spinner style={{ color: "#ff5733" }} />
                                    </div>
                                ) : (
                                    <div className="table-container">
                                        <Table striped hover className="table-dar">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "30%" }}>Date</th>
                                                    <th style={{ width: "40%" }}>Description</th>
                                                    <th style={{ width: "30%" }}>Action</th>
                                                </tr>
                                            </thead>
                                        </Table>

                                        {/* Scrollable tbody */}
                                        <div className="table-scroll">
                                            <Table striped hover className="table-dar">
                                                <tbody>
                                                    {enquiryData.length > 0 ? (
                                                        enquiryData.map((item, idx) => (
                                                            <tr key={idx}>
                                                                <td style={{ paddingTop: "7px" }}>{formatDate(item.createdAt)}</td>
                                                                <td style={{ paddingTop: "7px", width: '42%' }}>
                                                                    {item.description.split(" ").slice(0, 3).join(" ")}
                                                                    {item.description.split(" ").length > 3 ? "..." : ""}
                                                                </td>
                                                                <td className="table-actions">
                                                                    <button
                                                                        className="danger-btn"
                                                                        size="sm"
                                                                        title="Delete Enquiry"
                                                                        onClick={(e) => _deleteEnquiry(item._id)}
                                                                    >
                                                                        ✕
                                                                    </button>
                                                                    <button
                                                                        className="createbtnColor"
                                                                        size="sm"
                                                                        style={{ margin: "5px" }}
                                                                        title="View Enquiry Details"
                                                                        onClick={() => openEnquiryModal(item)}
                                                                    >
                                                                        <i className="fa-solid fa-eye fa-sm"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="3" style={{ textAlign: "center", color: "white" }}>
                                                                No Enquiries Found
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                )}

                                <SenderEnquiryDetailModel isOpen={enquiryModalOpen} toggle={closeEnquiryModal} enquiry={selectedEnquiry} />
                            </div>
                        </div>
                    </div>
                </Container>
                <HomeFooter />
                <SubFooter />
            </Container>
            <ToastContainer />
        </Fragment>
    );
};

export default UserDashboard;
