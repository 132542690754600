import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { addEditSliderDataAction } from "../../action/Admin.action";
import { setAlert } from "../../slices/home";
import { imgPath } from "../../admin/common/constant";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const List = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state } = useLocation();
  let data = state;

  const [isDisabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({
    name: data?.name || "",
    image: null,
    link: data?.link || "",
    status: data?.status !== undefined ? String(data.status) : "",
    _id: data?._id || "",
  });
  const [validated, setValidated] = useState(false);
  const [imagePreview, setImagePreview] = useState(
    data?.image ? imgPath(data.image) : ""
  );

  const validExtensions = ["jpg", "jpeg", "png", "gif"];

  // Handle Input Change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle Image Upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const ext = file.name.split(".").pop().toLowerCase();
    if (!validExtensions.includes(ext)) {
      return;
    }
    setFormData((prev) => ({ ...prev, image: file }));
    setImagePreview(URL.createObjectURL(file));
  };

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);

      let msg = formData._id
        ? "You have successfully updated the slider data."
        : "You have successfully added new slider data.";

      // **Convert to FormData**
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("link", formData.link);
      formDataToSend.append("status", formData.status);

      if (formData._id) {
        formDataToSend.append("_id", formData._id);
      }

      if (formData.image) {
        formDataToSend.append("image", formData.image); // New image file
      } else if (formData._id && data.image) {
        formDataToSend.append("existingImage", data.image); // Keep existing image
      }

      let resp = await addEditSliderDataAction(formDataToSend);

      if (resp.code === 200) {
        dispatch(setAlert({ open: true, severity: "success", msg, type: "" }));
        setTimeout(() => {
          navigate(`${process.env.PUBLIC_URL}/admin/sliderList`);
        }, 2000);
      } else {
        setDisabled(false);
        dispatch(
          setAlert({ open: true, severity: "danger", msg: resp.msg, type: "" })
        );
      }
    }
  };

  useEffect(() => {
    if (data) {
      setFormData({
        name: data.name || "",
        image: null,
        link: data.link || "",
        status: data.status !== undefined ? String(data.status) : "",
        _id: data._id || "",
      });

      if (data.image) {
        setImagePreview(imgPath(data.image));
      }
    }
  }, [data]);

  return (
    <div className="mt-2">
      <Card className="mt-5">
        <Card.Body>
          <Row className="justify-content-center mt-3 pt-2">
            <Col md={12} lg={12}>
              <h6 className="crdHd1">
                {formData._id ? "Update Details" : "Add Details"}
              </h6>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Group controlId="title">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter Title"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid title.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Link */}
                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Group controlId="link">
                      <Form.Label>Link</Form.Label>
                      <Form.Control
                        type="text"
                        name="link"
                        value={formData.link}
                        onChange={handleChange}
                        placeholder="Enter Link"
                        pattern="https?://.+"
                        isInvalid={
                          formData.link && !/^https?:\/\/.+/.test(formData.link)
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid URL.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Image Upload */}
                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Group controlId="image">
                      <Form.Label>Upload Image</Form.Label>
                      <Form.Control
                        type="file"
                        name="image"
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please upload an image.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Image Preview */}
                <Row className="mb-3">
                  <Col md={6} className="d-flex align-items-center">
                    {imagePreview && (
                      <img
                        src={imagePreview}
                        alt="Preview"
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                      />
                    )}
                  </Col>
                </Row>

                {/* Status */}
                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Group controlId="status">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please select a status.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Submit Button */}
                <div className="mt-4">
                  <button type="submit" className="btn vndrBtn text-white">
                    {formData._id ? "Update" : "Submit"}
                  </button>
                  <button
                    className="btn vndrBtn text-white ms-3"
                    onClick={() =>
                      navigate(`${process.env.PUBLIC_URL}/admin/sliderList`)
                    }
                  >
                    Back
                  </button>
                </div>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default List;
