import React, { Fragment, useEffect, useState } from "react";
import { getCategoryVendorDataAction } from "../../../action/Admin.action";
import { setAlert } from "../../../slices/home";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Dropdown,
  DropdownButton,
  Spinner,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import searchIcon from "../../../assets/images/frontPageImg/interface.png";
import {
  imgPath,
  objToArray,
  GetAllCountries,
  GetStatesOfCountry,
  GetCitiesOfState,
} from "../../../admin/common/function";
import HomeHeader from "../Header/HomeHeader";
import Headerlogin from "../Header/HeaderloginLite";
import HomeFooter from "../Footer/HomeFooter";
import SubFooter from "../Footer/SubFooter";

const CategoryList = ({ selected }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    businessCountry: "IN",
    businessState: "",
    businessCity: "",
  });
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const displayLimit = 12;

  // Toggle function to switch between "Show More" and "Show Less"
  const toggleShowAll = () => setShowAll((prevShowAll) => !prevShowAll);

  // Determine the number of items to display based on `showAll` state
  const displayedCategories = showAll
    ? dataList
    : dataList.slice(0, displayLimit);
  // Fetch all categories initially
  const fetchData = async () => {
    setLoading(true);
    setAlert({
      open: true,
      severity: "success",
      msg: "Loading...",
      type: "loader",
    });

    const resp = await getCategoryVendorDataAction();

    setAlert({ open: false });
    setLoading(false);
    if (resp && resp.code === 200) {
      setDataList(resp.data);
    } else {
      console.error("Error fetching data:", resp);
    }
  };
  // Fetch category suggestions based on input
  const fetchSuggestions = async (name) => {
    if (name.length >= 1) {
      const resp = await getCategoryVendorDataAction({ name });
      if (resp && resp.code === 200) {
        setSuggestions(resp.data);
      } else {
        console.error("Error fetching suggestions:", resp);
      }
    } else {
      setSuggestions([]);
    }
  };

  // Handle input change for search field
  const handleInputChange = async (e) => {
    const name = e.target.value;
    setFormData({ ...formData, name });
    setSelectedSuggestion(false);
    await fetchSuggestions(name);
  };

  // Handle suggestion click
  const handleSuggestionClick = (category) => {
    setFormData({ ...formData, name: category.name });
    setSuggestions([]);
    setSelectedSuggestion(true);
    fetchData();
  };

  // Handle form submission
  const handleSearch = async (e) => {
    e.preventDefault();
    if (formData.name) {
      await fetchData(); // Fetch data based on the input

      if (dataList.length > 0) {
        // Find the category that matches the formData.name
        const selectedCategory = dataList.find(
          (item) => item.name.toLowerCase() === formData.name.toLowerCase()
        );

        if (selectedCategory) {
          const selectedId = selectedCategory._id; // Get the ID of the matched category
          handleCardClick(selectedCategory); // Call handleCardClick with the selected ID
        } else {
          console.error("No matching category found");
        }
      } else {
        console.error("No categories found");
      }
    }
  };

  const handleChange = (name, event) => {
    const value = event.target.value;
    let updatedFormData = { ...formData, [name]: value };

    setFormData(updatedFormData);
  };

  // Handle card click
  const handleCardClick = (category) => {
    const { businessCountry, businessState, businessCity } = formData;

    // Navigate with additional selected state parameters
    navigate(`${process.env.PUBLIC_URL}/categoryProfileListing`, {
      state: {
        id: category,
        businessCountry,
        businessState,
        businessCity,
      },
    });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      businessState: "",
      businessCity: "",
    });
  }, [formData.businessCountry]);
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);
  const status = JSON.parse(localStorage.getItem("status"));
  return (
    <Fragment>
      {status === 1 ? <Headerlogin /> : <HomeHeader />}
      <section className="search-bar-section">
        <Container className="search-bar mt-2">
          <Row
            style={{
              marginTop: status === 1 ? "10px" : "100px",
              paddingBottom: "20px",
            }}
          >
            <Form onSubmit={handleSearch}>
              <Row className="g-2">
                <Col xs={12} md={2}>
                  <Form.Group>
                    <Form.Select
                      aria-label="Default select example"
                      className="filedbg customC-select"
                      value={
                        formData.businessCountry ? formData.businessCountry : ""
                      }
                      onChange={(e) => handleChange("businessCountry", e)}
                    >
                      <GetAllCountries />
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid Country.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={2}>
                  <Form.Group>
                    <Form.Select
                      aria-label="Default select example"
                      className="filedbg customC-select"
                      value={
                        formData.businessState ? formData.businessState : ""
                      }
                      onChange={(e) => handleChange("businessState", e)}
                    >
                      <GetStatesOfCountry
                        countryCode={
                          formData.businessCountry
                            ? formData.businessCountry
                            : ""
                        }
                      />
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid State.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={2}>
                  <Form.Group>
                    <Form.Select
                      aria-label="Default select example"
                      className="filedbg customC-select"
                      value={formData.businessCity ? formData.businessCity : ""}
                      onChange={(e) => handleChange("businessCity", e)}
                    >
                      <GetCitiesOfState
                        countryCode={
                          formData.businessCountry
                            ? formData.businessCountry
                            : ""
                        }
                        stateCode={
                          formData.businessState ? formData.businessState : ""
                        }
                      />
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid City.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs={12} md={4}>
                  <div className="d-flex search-baree">
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="🔍 Search for Category"
                      className="custom-inputee custom-placeholder"
                      onChange={handleInputChange}
                      value={formData.name}
                      autocomplete="off"
                    />
                    <button
                      className="search-button"
                      type="submit"
                      disabled={!formData.name}
                    >
                      <img src={searchIcon} alt="Search" />
                    </button>
                  </div>

                  {formData.name &&
                  suggestions.length > 0 &&
                  !selectedSuggestion ? (
                    <ul className="suggestions-list">
                      {suggestions.map((category) => (
                        <li
                          key={category._id}
                          onClick={() => handleSuggestionClick(category)}
                          className="suggestion-item"
                        >
                          <span className="suggestion-icon">🔍</span>
                          <div className="suggestion-details">
                            <span className="suggestion-name">
                              {category.name}
                            </span>
                            <span className="suggestion-category">
                              Category
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    formData.name &&
                    suggestions.length === 0 &&
                    !selectedSuggestion && (
                      <ul className="suggestions-list">
                        <li className="suggestion-item no-match">
                          <span className="suggestion-name">
                            No match found
                          </span>
                        </li>
                      </ul>
                    )
                  )}
                </Col>
              </Row>
            </Form>
          </Row>
        </Container>
      </section>
      <>
        {loading && (
          <div className="text-center my-4">
            <Spinner
              animation="border"
              role="status"
              style={{ color: "#ff5733" }}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}

        {!loading && (
          <section id="category" className="category-section">
            <Container>
              {dataList && dataList.length > displayLimit && (
                <div className="text-end mb-3 me-4">
                  <a className="view-all" onClick={toggleShowAll}>
                    {showAll ? "Show Less" : "View All"}
                  </a>
                </div>
              )}
              <Row>
                {displayedCategories &&
                  displayedCategories.map((item, index) => (
                    <Col
                      xs={6}
                      sm={4}
                      md={3}
                      lg={2}
                      className="text-center mb-4"
                      key={index}
                    >
                      <div
                        className="category-cardMain hand"
                        onClick={() => handleCardClick(item)}
                      >
                        <div className="icon-container">
                          <Card.Img
                            variant="top"
                            src={imgPath(item.categoryImg)}
                            alt={item.name}
                            className="card-image"
                          />
                          {/* <Card.Img
                            variant="top"
                            src={imgPath(item.whiteImg)}
                            alt={item.name}
                            className="card-image-hover"
                          /> */}
                        </div>
                        <Card.Body className="card-body-responsive">
                          <Card.Text>{item.name}</Card.Text>
                        </Card.Body>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Container>
          </section>
        )}
      </>
      <HomeFooter />
      <SubFooter />
    </Fragment>
  );
};

export default CategoryList;
