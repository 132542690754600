import React from "react";
import { Modal, Button, Table, Row, Col } from "react-bootstrap";
import { imgPath } from "../../../admin/common/function";

const SenderEnquiryDetailModel = ({
  isOpen,
  toggle,
  enquiry,
  fetchEnquiry,
}) => {
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };
  //   console.log("fetch", fetchEnquiry);
  return (
    <Modal show={isOpen} onHide={toggle} size="lg" centered>
      <Modal.Header
        className="useModelHeader custom-modal-header-close"
        closeButton
      >
        <Modal.Title className="">Enquiry Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="liteModal-content">
        {enquiry ? (
          <>
            {/* Enquiry Basic Information */}
            <div>
              <p className="allTextColor">
                <strong>Description:</strong> {enquiry.description}
              </p>
            </div>
            <Row>
              <Col>
                <p className="allTextColor">
                  <strong>Date:</strong> {formatDate(enquiry.createdAt)}
                </p>
              </Col>
            </Row>
            <hr />

            {/* Sender Information */}
            <h5 className="allTextColor">Sender Details:</h5>
            <div className="allTextColor p-1">
              <strong>Name:</strong> {enquiry.senderDetail.name}
            </div>
            <div className="allTextColor p-1">
              <strong>Email:</strong> {enquiry.senderDetail.email}
            </div>
            <div className="allTextColor p-1">
              <strong>Phone:</strong> {enquiry.senderDetail.phone}
            </div>
            <div className="allTextColor p-1">
              <strong>Category:</strong> {enquiry.senderDetail.categoryName}
            </div>
            <div className="allTextColor p-1">
              <strong>Sub-Category:</strong>{" "}
              {enquiry.senderDetail.subCategoryName}
            </div>
          </>
        ) : (
          <>
            <div>
              <p className="allTextColor">
                <strong>Description:</strong> {fetchEnquiry?.description}
              </p>
            </div>
            <Row>
              <Col>
                <p className="allTextColor">
                  <strong>Date:</strong> {formatDate(fetchEnquiry?.createdAt)}
                </p>
              </Col>
            </Row>
            <hr />

            {/* Sender Information */}
            <h5 className="allTextColor">Sender Details:</h5>
            <div className="allTextColor p-1">
              <strong>Name:</strong> {fetchEnquiry?.senderDetail.name}
            </div>
            <div className="allTextColor p-1">
              <strong>Email:</strong> {fetchEnquiry?.senderDetail.email}
            </div>
            <div className="allTextColor p-1">
              <strong>Phone:</strong> {fetchEnquiry?.senderDetail.phone}
            </div>
            <div className="allTextColor p-1">
              <strong>Category:</strong>{" "}
              {fetchEnquiry?.senderDetail.categoryName}
            </div>
            <div className="allTextColor p-1">
              <strong>Sub-Category:</strong>{" "}
              {fetchEnquiry?.senderDetail.subCategoryName}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer style={{ background: "#E6E6E6" }}>
        <Button className="btnColor" onClick={toggle}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SenderEnquiryDetailModel;
