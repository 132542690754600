import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faPencil,
  faTrash,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { setAlert } from "../../slices/home";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { getSliderDataAction } from "../../action/Admin.action";
import { imgPath } from "../../admin/common/constant";

import { _deleteAction, changeStatusAction } from "../../action/Admin.action";

const SliderList = () => {
  const dispatch = useDispatch();

  const [dataList, setDataList] = useState([]);
  const getDataList = async () => {
    const resp = await getSliderDataAction();
    if (resp.code === 200) {
      setDataList(resp.data);
    }
  };
  useEffect(() => {
    getDataList();
  }, []);

  const _delete = async (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            dispatch(
              setAlert({
                open: false,
                severity: "success",
                msg: "Loading...",
                type: "loader",
              })
            );

            const resp = await _deleteAction({
              _id: id,
              type: "slider",
            });
            if (resp.code === 200) {
              getDataList();
              dispatch(
                setAlert({
                  open: true,
                  severity: "success",
                  msg: "You have successfully deleted.",
                  type: "",
                })
              );
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const changeStatus = async (id, status, text) => {
    Swal.fire({
      title: "Confirm to " + text,
      text: "Are you sure to do this.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    }).then(async (result) => {
      if (result.value) {
        const resp = await changeStatusAction({
          _id: id,
          status: status,
          type: "slider",
        });
        if (resp.code === 200) {
          Swal.fire({
            title: text + "!",
            text: "Your lead has been " + text,
            icon: "success",
          });
          getDataList();
        }
      }
    });
  };

  return (
    <div className="mt-2">
      <Card className="mt-5">
        <Card.Body>
          <Row className="justify-content-center mt-3 pt-2">
            <Col md={8} lg={7}>
              <h6 className="crdHd1">Slider Management</h6>
            </Col>
            <Col md={4} lg={5} className="text-right text-end">
              <Link
                to={`${process.env.PUBLIC_URL}/admin/slider`}
                className="text-white btn pull-right vndrBtn"
              >
                Add
              </Link>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col lg={12}>
              <table className="table table-light">
                <thead className="headingtbl">
                  <tr>
                    <th scope="col">S. No.</th>
                    <th scope="col">Title</th>
                    <th scope="col">Link</th>
                    <th scope="col">Image</th>
                    <th scope="col">Status </th>
                    <th scope="col">Action </th>
                  </tr>
                </thead>
                <tbody>
                  {dataList.length > 0 ? (
                    dataList.map((item, index) => (
                      <tr key={item._id}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.link}</td>
                        <td>
                          {item.image ? (
                            <img
                              src={imgPath(item.image)}
                              alt="image"
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            "No Image"
                          )}
                        </td>
                        <td
                          className={
                            item.status == 1 ? "text-success" : "text-danger"
                          }
                        >
                          {item.status == 1 ? "Active" : "InActive"}
                        </td>
                        <td>
                          <Link
                            className="px-1"
                            to={`${process.env.PUBLIC_URL}/admin/slider/edit`}
                            state={item}
                            title="Edit Slider"
                          >
                            <FontAwesomeIcon icon={faPencil} size="xs" />
                          </Link>
                          &ensp;
                          {parseInt(item.status) === 1 ? (
                            <>
                              <span
                                className="px-1 hand"
                                onClick={(e) =>
                                  changeStatus(item._id, 0, "InActive")
                                }
                              >
                                <FontAwesomeIcon icon={faBan} size="xs" />
                              </span>
                              &ensp;
                            </>
                          ) : (
                            <>
                              <span
                                className="px-1 hand text-success"
                                title="Lead Active"
                                onClick={(e) =>
                                  changeStatus(item._id, 1, "Active")
                                }
                              >
                                <FontAwesomeIcon icon={faCheck} size="xs" />
                              </span>
                              &ensp;
                            </>
                          )}
                          &ensp;
                          <span
                            title="Delete Slider"
                            className="px-1 hand text-danger"
                            onClick={() => _delete(item._id)}
                          >
                            <FontAwesomeIcon icon={faTrash} size="xs" />
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SliderList;
