import React, { useState, useEffect } from "react";
import Header from "../../../FrontPage/Componets/Header/HeaderloginLite";
import background from "../../../assets/images/frontPageImg/freepik--background-simple--inject-126.png";
import Characters from "../../../assets/images/frontPageImg/freepik--Characters--inject-126.png";
import Plants from "../../../assets/images/frontPageImg/freepik--Plants--inject-126.png";
import Desk from "../../../assets/images/frontPageImg/freepik--Desk--inject-126.png";
import {
  selectUserTypeRegisterAction,
  getCategoryVendorDataAction,
  fileUploderAction,
  delelteFileAction,
} from "../../../action/Admin.action";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../slices/home";
import { Container, Col, Row, Form, Button, Modal } from "react-bootstrap";
import {
  GetAllCountries,
  GetStatesOfCountry,
  GetCitiesOfState,
} from "../../../admin/common/function";
import CheckmarkIcon from "../../../assets/images/frontPageImg/CheckmarkIcon.png";
import { FaArrowLeft } from "react-icons/fa";
import pdfIcon from "../../../assets/images/frontPageImg/pdfIcon.jpg";
import Preview from "../../../assets/images/Group1744.png";
import { imgPath, checkFile } from "../../../admin/common/function";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";
import TermsAndConditionPolicyModel from "./TermsAndConditionPolicyModel";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const UserRegister = (state) => {
  const location = useLocation();
  const { userType } = location.state || {};
  const [step, setStep] = useState(1);
  const [type, setType] = useState(userType || null);
  const [dataList, setDataList] = useState([]);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDisabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({ businessCountry: "IN" });
  const [subCategories, setSubCategories] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [pincode, setPincode] = useState("");
  const [previewURL, setPreviewURL] = useState(null);
  // const [previewFiles, setPreviewFiles] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [portfolioFiles, setPortfolioFiles] = useState([]);

  const [modalContent, setModalContent] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const PdfPreview = ({ fileUrl }) => {
    return (
      <div
        className="pdf-preview-container"
        style={{ width: "100%", height: "500px" }}
      >
        <Document
          file={fileUrl}
          onLoadError={(error) => console.error("Failed to load PDF:", error)}
          onSourceError={(error) =>
            console.error("Error in PDF source:", error)
          }
        >
          <Page pageNumber={1} width={500} />
        </Document>
      </div>
    );
  };

  const handleFileUpload = async (file, type) => {
    const formData = new FormData();
    formData.append("fileType", type);
    formData.append("file", file);

    try {
      const resp = await fileUploderAction(formData);
      if (resp.data) {
        if (type === "2") {
          setProductImages(resp.data);
        } else if (type === "1") {
          setPortfolioFiles(resp.data);
        }
      }
    } catch (error) {
      console.error("File upload failed:", error);
    }
  };

  // const handleFileUpload = async (file, type) => {
  //   const previewUrl = URL.createObjectURL(file);

  //   const formData = new FormData();
  //   formData.append("fileType", type);
  //   formData.append("file", file);

  //   try {
  //     const resp = await fileUploderAction(formData);
  //     if (resp.data) {
  //       const newFile = { ...resp.data, previewUrl };
  //       if (type === "2") {
  //         setProductImages((prevImages) => [...prevImages, newFile]);
  //       } else if (type === "1") {
  //         setPortfolioFiles((prevFiles) => [...prevFiles, newFile]);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("File upload failed:", error);
  //   }
  // };

  const removeFile = async (fileId, type) => {
    const targetState = type === "2" ? productImages : portfolioFiles;
    const fileToDelete = targetState.find((file) => file._id === fileId);

    if (!fileToDelete) {
      console.error("File not found in state");
      return;
    }

    try {
      const resp = await delelteFileAction({
        fileType: fileToDelete.fileType,
        _id: fileToDelete._id,
      });

      if (resp.code === 200) {
        if (type === "2") {
          setProductImages((prevFiles) =>
            prevFiles.filter((file) => file._id !== fileId)
          );
        } else if (type === "1") {
          setPortfolioFiles((prevFiles) =>
            prevFiles.filter((file) => file._id !== fileId)
          );
        }
      } else {
        console.error("Error from API:", resp.msg || "Unknown error");
      }
    } catch (error) {
      console.error("File deletion failed:", error);
    }
  };
  const getDataList = async () => {
    let resp;
    switch (userType) {
      case 3:
        resp = await getCategoryVendorDataAction({ type: "vendor" });
        break;
      case 4:
        resp = await getCategoryVendorDataAction({ type: "crew" });
        break;
      case 5:
        resp = await getCategoryVendorDataAction({ type: "other" });
        break;
      default:
        return;
    }
    if (resp && resp.code === 200) {
      setDataList(resp.data);
    }
  };

  const validateStep = () => {
    const phoneNumberPattern = /^[0-9]{10}$/;
    switch (step) {
      case 1:
        return (
          formData.businessContactName &&
          phoneNumberPattern.test(formData.businessContactNumber) &&
          (type !== 3 || type !== 4 || formData.categoryId) &&
          (type !== 5 || formData.workType) &&
          formData.email &&
          // (type !== 4 || formData.age) &&
          formData.businessCountry &&
          formData.businessState &&
          formData.businessCity &&
          formData.businessPostalCode &&
          (type !== 3 || formData.companyName)
        );
      case 2:
        return (
          formData.businessAddress &&
          formData.termsAccepted &&
          formData.privacyAccepted
        );
      default:
        return false;
    }
  };

  const handleNextStep = (event) => {
    if (validateStep()) {
      if (step === 1) {
        setStep(2);
      }
    } else {
      setValidated(true);
    }
  };

  const handlePreviousStep = () => {
    if (step === 2) {
      setStep(1);
    }
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false || !validateStep()) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      event.stopPropagation();
      setDisabled(true);

      let formDataData = { ...formData, userType };
      let postData = new FormData();
      for (let key in formDataData) {
        if (formDataData.hasOwnProperty(key)) {
          if (key === "crewResume") {
            if (formDataData[key]) {
              postData.append(key, formDataData[key]);
            }
          } else {
            postData.append(key, formDataData[key]);
          }
        }
      }

      let msg = "You have successfully registered.";
      let resp = await selectUserTypeRegisterAction(postData);

      if (resp.code === 200) {
        localStorage.setItem("status", JSON.stringify(resp.data.status));
        dispatch(
          setAlert({ open: true, severity: "success", msg: msg, type: "" })
        );
        localStorage.setItem("login", JSON.stringify(resp.data));
        localStorage.setItem("userType", resp.data.userType);

        setShowSuccessModal(true);
      } else {
        toast.error(resp.msg);
        setDisabled(false);
        dispatch(
          setAlert({ open: true, severity: "danger", msg: resp.msg, type: "" })
        );
      }
    }
  };

  const fetchPincode = async (city) => {
    setLoading(true);
    setError("");
    setPincode("");

    try {
      const response = await axios.get(
        `https://api.postalpincode.in/postoffice/${city}`
      );
      if (response.data[0].Status === "Success") {
        const pincodes = response.data[0].PostOffice.map((po) => po.Pincode);
        setPincode(pincodes.join(", "));
      } else {
        setError("Pincode not found for the specified city");
      }
    } catch (err) {
      setError("An error occurred while fetching the pincode");
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (name, event) => {
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    if (name === "crewResume") {
      let file = event.target.files[0];

      // Check if the file is a PDF
      if (file && file.type === "application/pdf") {
        setFormData({ ...formData, [name]: file });
        setPreviewURL(URL.createObjectURL(file)); // Create URL for preview
      } else {
        toast.error("Please upload a valid PDF file.");
        setFormData({ ...formData, [name]: null });
        setPreviewURL(null);
      }
    } else {
      let updatedFormData = { ...formData, [name]: value };
      if (name === "categoryId") {
        const selectedCategory = dataList.find((item) => item._id === value);
        setSubCategories(selectedCategory ? selectedCategory.subCategory : []);
        updatedFormData.subCategoryId = "";
      }
      if (name === "businessCity") {
        setFormData({ ...formData, [name]: value });
        fetchPincode(value);
      } else {
        setFormData(updatedFormData);
      }
    }
  };

  const isArtistCategory = () => {
    const selectedCategory = dataList.find(
      (item) => item._id === formData.categoryId
    );
    return selectedCategory?.name === "Artist";
  };

  useEffect(() => {
    getDataList();
  }, [state]);

  useEffect(() => {
    if (userType) {
      setType(userType);
    }
  }, [userType]);
  const handleContinue = () => {
    setShowSuccessModal(false);
    navigate(`${process.env.PUBLIC_URL}/user/dashboard`);
  };
  console.log(formData);
  return (
    <>
      <Container fluid={true} className="p-0">
        {/* <Header /> */}
        <button
          className="back-button"
          onClick={() => navigate(-1)}
          style={{
            background: "none",
            border: "none",
            display: "flex",
            alignItems: "center",
            paddingTop: "30px",
            marginLeft: "30px",
          }}
        >
          <FaArrowLeft
            style={{ width: "15px", height: "15px", marginRight: "8px" }}
          />
          <span className="allTextColor" style={{ marginLeft: "10px" }}>
            Back
          </span>
        </button>
        <Container className="pt-1">
          <Row
            className="justify-content-center "
            style={{ marginBottom: "20px" }}
          >
            <Col
              md={5}
              className="position-relative"
              style={{ marginTop: "50px" }}
            >
              <div className="image-container">
                <img
                  src={background}
                  alt="Background"
                  className="img-fluid"
                  style={{
                    borderRadius: "10px",
                    maxHeight: "315px",
                    width: "435px",
                    objectFit: "cover",
                    position: "absolute",
                    zIndex: 1,
                  }}
                />
                <img
                  src={Plants}
                  alt="Plants"
                  className="img-fluid"
                  style={{
                    borderRadius: "10px",
                    maxHeight: "188px",
                    width: "138px",
                    objectFit: "cover",
                    position: "relative",
                    top: "173px",
                    left: "0px",
                    zIndex: 2,
                  }}
                />
                <img
                  src={Desk}
                  alt="Desk"
                  className="img-fluid"
                  style={{
                    borderRadius: "10px",
                    maxHeight: "111px",
                    width: "235px",
                    objectFit: "cover",
                    position: "relative",
                    top: "200px",
                    left: "60px",
                    zIndex: 2,
                  }}
                />
                <img
                  src={Characters}
                  alt="Characters"
                  className="img-fluid"
                  style={{
                    borderRadius: "10px",
                    maxHeight: "343px",
                    width: "282px",
                    objectFit: "cover",
                    position: "absolute",
                    top: "37px",
                    left: "80px",
                    zIndex: 3,
                  }}
                />
              </div>
            </Col>
            <Col md={7}>
              <div className="profileUserRegistContainer">
                <h3
                  className="allTextColor"
                  style={{
                    borderBottom: "1px solid #313A47",
                    paddingBottom: "20px",
                  }}
                >
                  Profile
                </h3>

                <Form
                  className="pt-3 userForm"
                  noValidate
                  validated={validated}
                  onSubmit={(e) => handleSubmit(e)}
                >
                  {step === 1 ? (
                    <>
                      <Row>
                        {type === 3 && (
                          <Col md="6">
                            <div className="mb-2">
                              <Form.Group>
                                <Form.Label className="allTextColor mb-2">
                                  Company Name<span className="star">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="companyName"
                                  pattern="^[a-zA-Z][a-zA-Z0-9\s@#$%&*.,\-]{1,127}$"
                                  value={formData.companyName || ""}
                                  onChange={(e) =>
                                    handleChange("companyName", e)
                                  }
                                  autocomplete="off"
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please provide a valid Company Name.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                          </Col>
                        )}
                        <Col md="6">
                          <div className="mb-2">
                            <Form.Group>
                              <Form.Label className="allTextColor mb-2">
                                Contact Name<span className="star">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="businessContactName"
                                pattern="^[a-zA-Z][a-zA-Z0-9\s@#$%&*.,\-]{1,127}$"
                                value={formData.businessContactName || ""}
                                onChange={(e) =>
                                  handleChange("businessContactName", e)
                                }
                                autocomplete="off"
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                Please provide a valid Contact Name.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>
                        {type === 5 && (
                          <Col md="6">
                            <div className="mb-2">
                              <Form.Group>
                                <Form.Label className="allTextColor mb-2">
                                  Email ID<span className="star">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  name="email"
                                  value={formData.email || ""}
                                  onChange={(e) => handleChange("email", e)}
                                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                  autocomplete="off"
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please provide a valid Email ID.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                          </Col>
                        )}
                      </Row>

                      <Row>
                        {(type === 3 || type === 4) && (
                          <Col md="6">
                            <div className="mb-2">
                              <Form.Group>
                                <Form.Label className="allTextColor mb-2">
                                  {type === 4 ? "Department" : "Category"}
                                  <span className="star">*</span>
                                </Form.Label>
                                <Form.Select
                                  name="categoryId"
                                  className="customC-select"
                                  value={formData.categoryId || ""}
                                  onChange={(e) =>
                                    handleChange("categoryId", e)
                                  }
                                  required
                                >
                                  <option value="">
                                    {type === 4
                                      ? "Select Department"
                                      : "Select Category"}
                                  </option>

                                  {dataList.map((item, index) => (
                                    <option value={item._id} key={index}>
                                      {item.name}
                                    </option>
                                  ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                  {type === 4
                                    ? "Please provide a valid Department."
                                    : "Please provide a valid Category."}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                          </Col>
                        )}
                        {(type === 3 || type === 4) && (
                          <Col md="6">
                            <div className="mb-2">
                              <Form.Group>
                                <Form.Label className="allTextColor mb-2">
                                  Email ID<span className="star">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  name="email"
                                  value={formData.email || ""}
                                  onChange={(e) => handleChange("email", e)}
                                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                  autocomplete="off"
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please provide a valid Email ID.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        {(type === 3 || type === 4) && (
                          <Col md="6">
                            <div className="mb-2">
                              <Form.Group>
                                <Form.Label className="allTextColor mb-2">
                                  {type === 4 ? "Designation" : "Sub Category"}
                                </Form.Label>
                                <Form.Select
                                  name="subCategoryId"
                                  className="customC-select"
                                  value={formData.subCategoryId || ""}
                                  onChange={(e) =>
                                    handleChange("subCategoryId", e)
                                  }
                                >
                                  <option value="">
                                    {type === 4
                                      ? "Select Designation"
                                      : "Select Category"}
                                  </option>
                                  {subCategories.map((sub, index) => (
                                    <option value={sub._id} key={index}>
                                      {sub.name}
                                    </option>
                                  ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                  Please provide a valid Sub Category.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                          </Col>
                        )}
                        <Col md="6">
                          <div className="mb-2">
                            <Form.Group>
                              <Form.Label className="allTextColor mb-2">
                                Contact No<span className="star">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="businessContactNumber"
                                value={formData.businessContactNumber || ""}
                                onChange={(e) =>
                                  handleChange("businessContactNumber", e)
                                }
                                required
                                pattern="\d*"
                                minLength="10"
                                maxLength="10"
                                autocomplete="off"
                              />
                              <Form.Control.Feedback type="invalid">
                                Please provide a valid Contact No.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>
                        {type === 5 && (
                          <Col md="6">
                            <div className="mb-2">
                              <Form.Group>
                                <Form.Label className="allTextColor mb-2">
                                  Work Type<span className="star">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="workType"
                                  value={formData.workType || ""}
                                  onChange={(e) => handleChange("workType", e)}
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please provide a valid Work Type
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                          </Col>
                        )}
                      </Row>
                      {type === 4 && isArtistCategory() && (
                        <Row>
                          <Col md={6}>
                            <div className="mb-2">
                              <Form.Label className="allTextColor mb-2">
                                Age<span className="star"> *</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="filedbg cmnsz customC-select"
                                value={formData.age ? formData.age : ""}
                                onChange={(e) => handleChange("age", e)}
                                required
                              >
                                <option value="">Select Age</option>
                                <option value="1-10">1-10</option>
                                <option value="11-18">11-18</option>
                                <option value="19-25">19-25</option>
                                <option value="26-35">26-35</option>
                                <option value="36-50">36-50</option>
                                <option value="50 & above">50 & above</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please provide a valid Age.
                              </Form.Control.Feedback>
                            </div>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md="6">
                          <div className="mb-2">
                            <Form.Group>
                              <Form.Label className="allTextColor mb-2">
                                Country<span className="star">*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="filedbg customC-select"
                                value={
                                  formData.businessCountry
                                    ? formData.businessCountry
                                    : ""
                                }
                                onChange={(e) =>
                                  handleChange("businessCountry", e)
                                }
                                required
                              >
                                <GetAllCountries />
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please provide a valid Country.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="mb-2">
                            <Form.Group>
                              <Form.Label className="allTextColor mb-2">
                                State<span className="star">*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="filedbg customC-select"
                                value={
                                  formData.businessState
                                    ? formData.businessState
                                    : ""
                                }
                                onChange={(e) =>
                                  handleChange("businessState", e)
                                }
                                required
                              >
                                <GetStatesOfCountry
                                  countryCode={
                                    formData.businessCountry
                                      ? formData.businessCountry
                                      : ""
                                  }
                                />
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please provide a valid State.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6">
                          <div className="mb-2">
                            <Form.Group>
                              <Form.Label className="allTextColor mb-2">
                                City<span className="star">*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="filedbg customC-select"
                                value={
                                  formData.businessCity
                                    ? formData.businessCity
                                    : ""
                                }
                                onChange={(e) =>
                                  handleChange("businessCity", e)
                                }
                                required
                              >
                                <GetCitiesOfState
                                  countryCode={
                                    formData.businessCountry
                                      ? formData.businessCountry
                                      : ""
                                  }
                                  stateCode={
                                    formData.businessState
                                      ? formData.businessState
                                      : ""
                                  }
                                />
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please provide a valid City.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="mb-2">
                            <Form.Group>
                              <Form.Label className="allTextColor mb-2">
                                Pin Code<span className="star">*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Select Pin Code"
                                className="filedbg customC-select "
                                value={formData.businessPostalCode || ""}
                                onChange={(e) =>
                                  handleChange("businessPostalCode", e)
                                }
                                required
                              >
                                <option value="">Select Pin Code</option>
                                {pincode &&
                                  pincode.split(", ").map((pin, index) => (
                                    <option
                                      key={index}
                                      value={pin}
                                      disabled={pin.length !== 6}
                                    >
                                      {pin}
                                    </option>
                                  ))}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please provide a valid Pin Code.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12">
                          <button
                            className="mt-3 btn btnColor"
                            onClick={handleNextStep}
                            style={{
                              backgroundColor: "#AA1163",
                              fontSize: "1rem",
                            }}
                          >
                            Next
                          </button>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row>
                        <Col md="6">
                          <div className="mb-2">
                            <Form.Group>
                              <Form.Label className="allTextColor mb-2">
                                Address<span className="star">*</span>
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                name="businessAddress"
                                rows="2"
                                value={formData.businessAddress || ""}
                                onChange={(e) =>
                                  handleChange("businessAddress", e)
                                }
                                autocomplete="off"
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                Please provide a valid Address.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="mb-2">
                            <Form.Group>
                              <Form.Label className="allTextColor mb-2">
                                Description
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                name="description"
                                rows="2"
                                value={formData.description || ""}
                                onChange={(e) => handleChange("description", e)}
                                autocomplete="off"
                              />
                              <Form.Control.Feedback type="invalid">
                                Please provide a valid Description.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>
                      </Row>
                      {(type === 3 || type === 4) && (
                        <Row>
                          <Col md="6">
                            <div className="mb-2">
                              <Form.Group>
                                <Form.Label className="allTextColor mb-2">
                                  GST Number
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="businessGstNumber"
                                  value={formData.businessGstNumber || ""}
                                  onChange={(e) =>
                                    handleChange("businessGstNumber", e)
                                  }
                                  autocomplete="off"
                                  pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                                  minLength={1}
                                  maxLength={15}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please provide a valid GST Number.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-2">
                              <Form.Group>
                                <Form.Label className="allTextColor mb-2">
                                  Pan No.
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="businessPan"
                                  value={formData.businessPan || ""}
                                  onChange={(e) =>
                                    handleChange("businessPan", e)
                                  }
                                  pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                                  minLength={10}
                                  maxLength={10}
                                  autocomplete="off"
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please provide a valid Pan No.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                          </Col>
                        </Row>
                      )}
                      {type === 4 && (
                        <Row>
                          <Col md="6">
                            <div className="mb-2">
                              <Form.Group>
                                <Form.Label className="allTextColor mb-2">
                                  Website
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="website"
                                  value={formData.website || ""}
                                  onChange={(e) => handleChange("website", e)}
                                  autocomplete="off"
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please provide a valid Website.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-2">
                              <Form.Group>
                                <Form.Label className="allTextColor mb-2">
                                  Imdb Link
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="imDbLink"
                                  value={formData.imDbLink || ""}
                                  onChange={(e) => handleChange("imDbLink", e)}
                                  autocomplete="off"
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please provide a valid Imdb Link.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                          </Col>
                        </Row>
                      )}
                      {type === 5 && (
                        <Row>
                          <Col md="6">
                            <div className="mb-2">
                              <Form.Group>
                                <Form.Label className="allTextColor mb-2">
                                  Website
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="website"
                                  value={formData.website || ""}
                                  onChange={(e) => handleChange("website", e)}
                                  autocomplete="off"
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please provide a valid Website.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        {type === 3 && (
                          <Col md="6">
                            <div>
                              <Form.Group>
                                <Form.Label className="allTextColor mb-2">
                                  Add Product Image
                                </Form.Label>
                                <div
                                  className="file-upload-container"
                                  onClick={() =>
                                    document
                                      .getElementById("productImageInput")
                                      .click()
                                  }
                                >
                                  <Form.Control
                                    id="productImageInput"
                                    type="file"
                                    name="productImg"
                                    onChange={(e) =>
                                      handleFileUpload(e.target.files[0], "2")
                                    }
                                    className="file-input"
                                  />
                                  <div className="plus-icon">+</div>
                                </div>
                                {formErrors.productImage_error && (
                                  <div className="error-message">
                                    {formErrors.productImage_error}
                                  </div>
                                )}
                              </Form.Group>

                              <div className="image-previews">
                                {productImages.map((file, index) => (
                                  <div key={file._id} className="preview-item">
                                    <img
                                      src={imgPath(file.filePath)}
                                      // src={
                                      //   file.previewUrl ||
                                      //   imgPath(file.filePath)
                                      // }
                                      alt={`Product Image ${index + 1}`}
                                      className="preview-image"
                                    />
                                    <Button
                                      className="remove-icon"
                                      onClick={() => removeFile(file._id, "2")}
                                    >
                                      &times;
                                    </Button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Col>
                        )}
                        {type === 4 && (
                          <Col md="6">
                            <div className="mb-2">
                              <Form.Group>
                                <Form.Label className="allTextColor mb-2">
                                  Resume
                                </Form.Label>
                                <div
                                  className="file-upload-container"
                                  onClick={() =>
                                    document
                                      .getElementById("ResumeInput")
                                      .click()
                                  }
                                >
                                  <Form.Control
                                    id="ResumeInput"
                                    type="file"
                                    name="crewResume"
                                    accept="application/pdf"
                                    onChange={(e) =>
                                      handleChange("crewResume", e)
                                    }
                                    className="file-input"
                                  />
                                  <div className="plus-icon">+</div>
                                </div>
                                {previewURL && (
                                  <img
                                    src={pdfIcon}
                                    alt="PDF Icon"
                                    style={{
                                      width: "60px",
                                      height: "60px",
                                      border: "1px solid #ddd",
                                      borderRadius: "5px",
                                      marginTop: "10px",
                                    }}
                                  />
                                )}
                              </Form.Group>
                            </div>
                          </Col>
                        )}
                        {(type === 3 || type === 4) && (
                          <Col md="6">
                            <div className="mb-2">
                              <Form.Group>
                                <Form.Label className="allTextColor mb-2">
                                  Add Portfolio
                                </Form.Label>
                                <div
                                  className="file-upload-container"
                                  onClick={() =>
                                    document
                                      .getElementById("portfolioInput")
                                      .click()
                                  }
                                >
                                  <Form.Control
                                    id="portfolioInput"
                                    type="file"
                                    name="portFolio"
                                    onChange={(e) =>
                                      handleFileUpload(e.target.files[0], "1")
                                    }
                                    className="file-input"
                                  />
                                  <div className="plus-icon">+</div>
                                </div>
                                {formErrors.portfolio_error && (
                                  <div className="error-message">
                                    {formErrors.portfolio_error}
                                  </div>
                                )}
                              </Form.Group>

                              <div
                                className="file-previews"
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  flexWrap: "wrap",
                                  marginTop: "6px",
                                }}
                              >
                                {portfolioFiles.map((file, index) => (
                                  <div
                                    key={file._id}
                                    className="file-preview-item"
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                  >
                                    {file.filePath.endsWith(".pdf") ? (
                                      <img
                                        src={pdfIcon}
                                        alt="PDF Icon"
                                        style={{
                                          width: "60px",
                                          height: "60px",
                                          border: "1px solid #ddd",
                                          borderRadius: "5px",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={`${imgPath(file.filePath)}`} // Replace with actual image path
                                        alt={`Preview ${index}`}
                                        style={{
                                          width: "60px",
                                          height: "60px",
                                          borderRadius: "5px",
                                          objectFit: "cover",
                                        }}
                                      />
                                    )}
                                    <Button
                                      className="remove-icon"
                                      style={{
                                        position: "absolute",
                                        top: "-5px",
                                        right: "-5px",
                                        backgroundColor: "#6c63ff",
                                        borderRadius: "50%",
                                        padding: "5px",
                                        fontSize: "12px",
                                      }}
                                      onClick={() => removeFile(file._id, "1")}
                                    >
                                      &times;
                                    </Button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col md="12">
                          <Form.Group className="mb-3">
                            <Form.Check
                              type="checkbox"
                              id="termsConditions"
                              label={
                                <span className="allTextColor">
                                  I agree to the{" "}
                                  <span
                                    className="privacy-policy-link"
                                    onClick={() =>
                                      handleModalOpen(
                                        "Terms and Conditions Content"
                                      )
                                    }
                                  >
                                    Terms and Conditions
                                  </span>
                                  .<span className="star">*</span>
                                </span>
                              }
                              checked={formData.termsAccepted}
                              onChange={(e) => handleChange("termsAccepted", e)}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              You must agree to the Terms and Conditions.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group className="mb-3">
                            <Form.Check
                              type="checkbox"
                              id="privacyPolicy"
                              label={
                                <span className="allTextColor">
                                  I accept the{" "}
                                  <span
                                    className="privacy-policy-link"
                                    onClick={() =>
                                      handleModalOpen("Privacy Policy Content")
                                    }
                                  >
                                    Privacy Policy
                                  </span>
                                  .<span className="star">*</span>
                                </span>
                              }
                              checked={formData.privacyAccepted}
                              onChange={(e) =>
                                handleChange("privacyAccepted", e)
                              }
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              You must accept the Privacy Policy.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <TermsAndConditionPolicyModel
                        show={showModal}
                        onHide={handleModalClose}
                        content={modalContent}
                      />
                      <div>
                        <button
                          className="mt-3 btn createbtnColor"
                          disabled={isDisabled}
                          style={{
                            fontSize: "1rem",
                          }}
                        >
                          Register
                        </button>{" "}
                        <button
                          className="mt-3 btn btnColor"
                          onClick={handlePreviousStep}
                          style={{
                            fontSize: "1rem",
                            marginLeft: "10px",
                          }}
                        >
                          Back
                        </button>
                      </div>
                    </>
                  )}
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <ToastContainer position="top-right" autoClose={3000} />
      <Modal
        show={showSuccessModal}
        onHide={() => {}}
        centered
        backdrop="static"
      >
        <Modal.Body className="text-center liteModal-content">
          <div className="my-4">
            <img
              src={CheckmarkIcon}
              width={50}
              height={50}
              alt="Success Checkmark"
            />
          </div>
          <h4 style={{ color: "grey" }}>Registration Successful!</h4>
          <p>You have successfully registered!</p>

          {/* <Modal.Footer className="liteModal-content"> */}
          <Button className="btnColor" onClick={handleContinue}>
            Continue
          </Button>
        </Modal.Body>
        {/* </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default UserRegister;
